import React from 'react'
import PropTypes from 'prop-types'

export const EmailIcon = ({ fill, stroke }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <g id="Group_407" data-name="Group 407" transform="translate(-54 -9.5)" stroke="#000000">
        <path id="Path_698" data-name="Path 698" d="M1789,424h-19v16h19Zm-20-1v18h21V423Z" transform="translate(-1715 -413.5)" fill={stroke}/>
        <path id="Path_699" data-name="Path 699" d="M1769.36,424.764,1770,424l9.5,8,9.5-8,.64.764-10.14,8.543Z" transform="translate(-1715 -413.5)" fill={fill}/>
      </g>
    </svg>
  )
}

EmailIcon.propTypes = { fill: PropTypes.string, stroke: PropTypes.string }
EmailIcon.defaultProps = {
  fill: "#c4c4c4",
  stroke: "#fff"
}
