import styled from 'styled-components';

export const StyledImg = styled.img`
  width: 54px;
  height: 54px;
  margin: .5em;
  transition: 0.3s;
  border: 1px solid transparent;
  grid-column: 1 / 2;
  grid-row: 1 / -1;
`;

export const StyledDiv = styled.div`
  opacity: ${p => p.show ? 1 : 0};
  transition:0.5s opacity;
  display: grid;
  color: white;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;
  grid-template-rows: 1fr;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  max-width: 200px;
  font-family: Radnika-Regular;
  padding-left: .7em;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .8);
  margin-right: 20px;
  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const StyledP = styled.p`
  font-size: 10px;
  color: #7A303F;
  text-transform: uppercase;
  margin-bottom: ${p => p.reset ? 2.5 : 0}em;
`;
export const StyledPIn = styled.p`
  font-size: 13px;
  color: white;
  margin-bottom: 0;
  margin-top: ${p => p.reset ? 1 : 0}em;
`;

export const StyledDivText = styled.div`
  grid-column: 2;
  grid-row: 1;
`;
