 
import React from 'react'

const ChatIcon = React.memo(() => {

    return (
        <svg id="character" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30.001 33.001">
            <g id="Union_6" data-name="Union 6" transform="translate(-4711.999 15729.001)" fill="rgba(42,42,42,0.8)" className="background-chat-icon">
                <path d="M 4727.47998046875 -15696.9013671875 L 4725.8955078125 -15699.27734375 L 4725.7470703125 -15699.5 L 4725.4794921875 -15699.5 L 4712.49853515625 -15699.5 L 4712.49853515625 -15728.5009765625 L 4741.49951171875 -15728.5009765625 L 4741.49951171875 -15699.5 L 4729.47802734375 -15699.5 L 4729.21044921875 -15699.5 L 4729.06201171875 -15699.27734375 L 4727.47998046875 -15696.9013671875 Z" stroke="none"/>
                <path d="M 4740.99951171875 -15700 L 4740.99951171875 -15728.0009765625 L 4712.99853515625 -15728.0009765625 L 4712.99853515625 -15700 L 4725.4794921875 -15700 L 4729.47802734375 -15700 L 4740.99951171875 -15700 M 4727.48046875 -15696 L 4725.4794921875 -15699 L 4729.47802734375 -15699 L 4727.48046875 -15696 Z M 4741.99951171875 -15699 L 4729.47802734375 -15699 L 4725.4794921875 -15699 L 4711.99853515625 -15699 L 4711.99853515625 -15729.0009765625 L 4741.99951171875 -15729.0009765625 L 4741.99951171875 -15699 Z" stroke="none" fill="#c4c4c4"/>
            </g>
            <g id="Group_189" data-name="Group 189" transform="translate(0.001 0.001)">
                <path id="Path_709" data-name="Path 709" d="M3.578,2.293A7.594,7.594,0,0,1,8.527.625a7.594,7.594,0,0,1,4.949,1.668,4.965,4.965,0,0,1,0,8.039A7.594,7.594,0,0,1,8.527,12,8.312,8.312,0,0,1,5.6,11.48a6.119,6.119,0,0,1-3.855,1.395.182.182,0,0,1-.109-.041.271.271,0,0,1-.082-.1.2.2,0,0,1,.041-.232q.1-.1.328-.383a7.8,7.8,0,0,0,.615-.957,5.513,5.513,0,0,0,.547-1.271A4.925,4.925,0,0,1,1.527,6.313,5.025,5.025,0,0,1,3.578,2.293Z" transform="translate(7 8)" fill="#c4c4c4"/>
            </g>
        </svg>
    )
})

export {ChatIcon}