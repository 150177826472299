import { UIActionType } from '../../types/ui'

const defaultState = {
  theme: 'default'
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UIActionType.UPDATE_HEADER_THEME:
      return { ...state, theme: action.payload.theme }

    default: return { ...state }
  }
}
