import { FavoritesActionType } from '../../types/favorites'

const defaultState = {
  loading: false,
  products: []
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case FavoritesActionType.ADD:
      return {
        loading: false,
        products: [
          ...state.products.filter(item => item.id !== action.payload.id),
          action.payload
        ]
      }

    case FavoritesActionType.REMOVE:
      return {
        loading: false,
        products: state.products.filter(item => item.id !== action.payload.id)
      }

    default:
      return state
  }
}

