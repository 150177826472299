import React from 'react'
import PropTypes from 'prop-types'

const CloseIcon = React.memo(({ fill, stroke, width = 30, height = 30 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22">
      <g fill={fill} transform="translate(6.441 6.441)">
        <path stroke={stroke} d="M5718.845,1317.618l9.38,9.38" transform="translate(-5718.845 -1317.618)" />
        <path stroke={stroke} d="M5728.226,1317.618l-9.38,9.38" transform="translate(-5718.846 -1317.618)" />
      </g>
    </svg>
  )
})

CloseIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
}

CloseIcon.defaultProps = {
  fill: "#2a2a2a",
  stroke: "#fff"
}

export { CloseIcon }
