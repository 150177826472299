import styled from 'styled-components';
import { Button } from 'antd';

const MenuFooter = styled.footer`
  height: 120px;
  position: absolute;
  left:0;
  right:0;
  bottom: 0;
  color: #C4C4C4;
  margin-bottom:0px;
  .mobile{
    height: 64px
  };`;

const SocialMediaDiv = styled.div`
  grid-row: 1;
  grid-column: 5;
  align-self: left;
  &.mobile {
    grid-column: 3 / 5;
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 24px;
  }
`;

const MenuHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-row: 1;
  grid-column: 1 / -1;
  height: 34px;
  opacity: 0.5;
  transition:0.3s all;
  padding-top: 50px;
  div {
    position: unset;
  }
  .action-menu {
    border: 1px solid #777;
    grid-column: 1;
    justify-self: center;
    margin-left: 15px;
    cursor: pointer;
  }
  :hover {
    1px solid #2a2a2a;
    opacity:1;transition:0.3s all
  };
  &.mobile {
    padding-top: 15px;
  }
  .icon {
    img {
      width: 100%;
      padding: 0;
    };
  }`;

const MainContent = styled.main`
  grid-column: 1 / -1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 10% repeat(3, 30%);
  &.mobile {
    padding-top:30px;
  } 
  > div {
    padding-left: 2em;
    cursor: pointer;
    padding-right: 5em;
    .content{
      max-height: 43vh;
      padding-right: 16px;
      overflow-y: scroll;
      margin-bottom: 16px;
      @media (max-height: 600px){
        max-height: 60vh;
      }
    }
  }
  select,textarea,input,p{
    font-family:DIN2014;
    font-size:14px
  }`;
  const ActionButtonLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
   left:${p => 42 * p.left}px;
   top:0;
   width: 36px;
   height: 36px; 
   &.mobile{width:20px; height:20px;top:0; left:${p => 30 * p.right}px; border: 1px solid #eee; border-radius: 50%;}
  .menu-top{
    margin-left: 15px;
    &:hover{
      opacity:1;
      cursor: pointer;
      transition:0.3s all;
      svg{
        rect{
          fill: #e3e3e3 !important;
        }
        path{
          stroke: #121212 !important;
          cursor: pointer;
        }
        
      }
    }
  }
  `

const SocialMediaButtons = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 36px; 
  height: 36px;
  border: none !important;
  &:hover svg path {
    fill: black;
  }
  &.mobile{
    width:20px; 
    height:20px;
    margin-left: 2em;
  }`;

const MenuItem = styled.li`
  cursor:pointer;
  display:block;
  font-size:30px;
  font-family:Didot;
  color:#777777;
  margin-bottom:30px;
  letter-spacing: -1px;
  transition: .3s;
  &.mobile {
    margin-bottom: 15px;
  };
  :hover {
    color: black;
    padding-left: 1em;
  }
`
const SecondaryButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 40px;
  background: #E9E9E9;
  border: 1px solid #7F7F7F;
  font-size:14px;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100% !important;
  font-family:Radnika-Regular;
  color: #7F7F7F;
  text-transform: uppercase;
  :focus,:hover{
    outline:none;
    border:1px solid #703540;
    color:#703540!important
  }`

const StyledMenu = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 20% 60% 20%;
  grid-template-columns: 50% 50%;
`;

const StyledMenuOptions = styled.div`
  grid-column: 2 / -1;
  padding-left: 3.5em;
  cursor: pointer;
  &.mobile {
    padding-left: 0;
  }
`;

export { 
  SecondaryButton,
  MenuItem,
  ActionButtonLeft,
  MainContent,
  MenuHeader,
  SocialMediaButtons,
  StyledMenu,
  SocialMediaDiv,
  StyledMenuOptions,
  MenuFooter
}