import React from 'react'

const HotspotInfoIcon = React.memo(() => {
  return (
    <svg id="hotspot" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 62 54">
      <g id="Rectangle_257" data-name="Rectangle 257" transform="translate(13.001 18)" fill="rgba(0,0,0,0.6)" className="background-info-icon" stroke="#fff" strokeWidth="1">
        <rect width="36" height="36" stroke="none"/>
        <rect x="0.5" y="0.5" width="35" height="35" fill="none"/>
      </g>
      <rect id="Rectangle_258" data-name="Rectangle 258" width="4" height="4" transform="translate(29.001 34)" fill="#fff"/>
      <g id="Group_317" data-name="Group 317" transform="translate(981 931) rotate(-180)" opacity="0">
        <path id="Union_2" data-name="Union 2" d="M5389-1248v-16h29l2.5-4,2.5,4h28v16Z" transform="translate(-4470 2179)" fill="#2a2a2a" opacity="0.95"/>
        <text id="know_more" data-name="know more" transform="translate(976 930) rotate(180)" fill="#c4c4c4" fontSize="10" fontFamily="DIN2014-Regular, 'DIN 2014'" letterSpacing="0.015em"><tspan x="-23.875" y="10">know more</tspan></text>
      </g>
    </svg>
  )
})

export {HotspotInfoIcon}