import React from 'react'
import PropTypes from 'prop-types'

const FullsizeIcon = React.memo(({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.909" height="16.12" viewBox="0 0 17.909 16.12">
      <g id="Group_206" data-name="Group 206" transform="translate(-1824.046 -356.454)">
        <path id="Path_237" data-name="Path 237" d="M20.682,322.074h4.573a.2.2,0,0,0,.2-.2V317.3" transform="translate(1815.5 49.5)" fill="none" stroke={fill} strokeWidth="2"/>
        <path id="Path_238" data-name="Path 238" d="M14.318,307.954H9.746a.2.2,0,0,0-.2.2v4.573" transform="translate(1815.5 49.5)" fill="none" stroke={fill} strokeWidth="2"/>
      </g>
    </svg>
  )
})

FullsizeIcon.propTypes = {
  fill: PropTypes.string
}

FullsizeIcon.defaultProps = {
  fill: "#FFF"
}

export {FullsizeIcon}