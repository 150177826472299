import React from 'react';
import PropTypes from 'prop-types';

const MenuPlay = React.memo(({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g transform="translate(-1816 -290)">
            <path id="Path_235" data-name="Path 235" d="M11.966,250.168a.2.2,0,0,0-.3.174v14.316a.2.2,0,0,0,.3.174l12.725-7.158a.2.2,0,0,0,0-.348Z" transform="translate(1815.5 49.5)" fill={fill} />
        </g>
    </svg>
));
MenuPlay.propTypes = { fill: PropTypes.string };
MenuPlay.defaultProps = { fill: "#fff" };
export { MenuPlay }