import { REHYDRATE } from 'redux-persist'
import { VisitorActionType } from './../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  visitor: null,
  visitorInput: {
    name: '',
    email: '',
    term: false,
    position: '',
    country: ''
  },
  token: null,
  progress: 0,
  visitedActs: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const reduxState = { ...defaultState, ...action.payload?.visitorAPI }
      return {
        ...state,
        visitor: reduxState.visitor,
        token: reduxState.token,
        progress: reduxState.progress,
        visitedActs: reduxState.visitedActs
      }

    case VisitorActionType.POST_VISITOR_API:
      return {
        ...state,
        loading: true,
        visitor: action.payload.data
      }

    case VisitorActionType.POST_VISITOR_API_SUCCESS:
      return {
        ...state,
        loading: false,
        visitor: action.payload.data,
        successMessage: 'success',
      }

    case VisitorActionType.POST_VISITOR_API_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data
      }

    case VisitorActionType.UPDATE_VISITOR_INPUT:
      return {
        ...state,
        visitorInput: {
          ...state.visitorInput,
          [action.payload.property]: action.payload.value
        }
      }

    case VisitorActionType.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload
      }

    case VisitorActionType.UPDATE_PROGRESS:
      const visited = state.visitedActs.includes(action.payload.act)
      const progress = visited ? state.progress : (action.payload.percent + state.progress)
      const visitedActs = visited ? state.visitedActs : [...new Set([...state.visitedActs, action.payload.act])]
      const maxProgress100 = Math.min(progress, 100)
      return {
        ...state,
        progress: maxProgress100,
        visitedActs
      }

    default: return { ...state }
  }
}
