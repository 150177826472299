import React from 'react'
import PropTypes from 'prop-types'

const InstagramIcon = React.memo(({ fill, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
      <path id="Path_191" data-name="Path 191" d="M40.87.123a5.947,5.947,0,0,0-4.178,1.606A5.919,5.919,0,0,0,35.082,5.9c-.046,1.005-.313,8.6.463,10.594a5.041,5.041,0,0,0,2.91,2.9,7.3,7.3,0,0,0,2.415.462c8.861.4,12.145.183,13.53-3.364a7.238,7.238,0,0,0,.462-2.41c.4-8.884-.066-10.809-1.61-12.352C52.027.507,50.586-.325,40.87.123Zm.081,17.945a5.51,5.51,0,0,1-1.848-.341,3.255,3.255,0,0,1-1.889-1.883c-.591-1.514-.4-8.7-.342-9.866a4.246,4.246,0,0,1,1.087-2.985C38.954,2,40.24,1.513,48.993,1.908a4.27,4.27,0,0,1,2.992,1.084c1,.993,1.489,2.288,1.087,11.008a5.474,5.474,0,0,1-.342,1.843C51.829,18.151,49.757,18.472,40.951,18.067ZM49.09,4.69A1.195,1.195,0,1,0,50.284,3.5,1.193,1.193,0,0,0,49.09,4.69Zm-9.227,5.3a5.11,5.11,0,1,0,5.109-5.1A5.1,5.1,0,0,0,39.863,9.988Zm1.793,0A3.317,3.317,0,1,1,44.972,13.3,3.313,3.313,0,0,1,41.656,9.988Z" transform="translate(-35)" fill={fill}/>
    </svg>
  )
})

InstagramIcon.propTypes = {
  fill: PropTypes.string
}

InstagramIcon.defaultProps = {
  fill: "#ccc"
}

export {InstagramIcon}