import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { BlueDot } from '../../assets';
import { RenderMap, isMobile } from '../../utils';
import TabsPanel from '../tabs-panel';
import { useHistory } from 'react-router';
import { updateProgress } from '../../effects/actions';

const Compass = React.memo(() => <div className="compass-container no-touch absolute"><div className="relative h-100 w-100"><CompassPointer alt="compass" className="no-touch" ref={r => window.compass = r} src={BlueDot} /></div></div>)

function LeftMap({ currentAct, progress, initialCompass }) {
    const history = useHistory()
    const [expanded, setExpand] = React.useState();
    const miniMapEffect = `${expanded ? `fadeout-transition` : `fadein-transition`}`;
    const expandMapEffect = `${expanded ? `fadein-transition` : `fadeout-transition`}`;

    if (!currentAct) return null

    if (expanded) return (
        <MapDiv className={`absolute expanded ${expandMapEffect}`}>
            <TabsPanel initialCompass={initialCompass} panoramaAct={currentAct.act} onSetExpand={() => setExpand(false)}><Compass /></TabsPanel>
        </MapDiv>
    )

    return (
        <MapDiv className={`d-flex flex-row ${miniMapEffect}`}>
            <div id="left" className="relative">

                {isMobile() && (
                    <MapZone className="compass-mobile">
                        <Compass />
                        <div className="compass-texts">
                            <p className="compass-title">{currentAct.tab}</p>
                            <p className="compass-subtitle">{currentAct.title}</p>
                        </div>
                        <div onClick={() => setExpand(true)} className="button-maximizar" />
                    </MapZone>
                )}

                {!isMobile() && (
                    <MapZone className="compass-web">
                        <div className="image-container">
                            <RenderMap hoverProduct={false} map={currentAct.act} height="140px" width="100%" history={history} />
                            <Compass />
                        </div>
                        <div className="right-text-container">
                            <div className="top-texts">
                                <p className="compass-title">{currentAct.tab}</p>
                                <p className="compass-subtitle">{currentAct.title}</p>
                            </div>

                            <div className="bottom-texts">
                                <p className="compass-title">{progress}%</p>
                                <p className="compass-subtitle">Completed</p>
                            </div>
                        </div>
                        <div onClick={() => setExpand(true)} className="button-maximizar" />
                    </MapZone>
                )}
            </div>
        </MapDiv>
    )
}

const mapStateToProps = ({ visitorAPI }) => {
    const { progress } = visitorAPI
    return { progress }
}
export default connect(mapStateToProps, updateProgress)(React.memo(LeftMap))


export const MapZone = styled.div`
    margin: 16px;
    z-index:2;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; 
    width: auto;
    height: inherit;
    margin: 0 auto;

    @media (max-width: 767px) {
        height: 65px;
        width: 170px;
        display: flex;

        .compass-container {
            background-color:rgb(0 0 0 / 80%);
            width: 65px;
            height: 65px;
            position:relative;
        }

        .compass-texts {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 10px;
            text-transform: uppercase;
            color: #FFFFFF;

            .compass-title {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                letter-spacing: .5px;
            }

            .compass-subtitle {
                font-size: .7rem;
                padding: 0;
                margin: 0;
                line-height: 9px;
                letter-spacing: .5px;
            }
        }

        .button-maximizar {
            position: absolute;
            z-index: 3;
            cursor: pointer;
            left: 15px;
            top: 0px;
            width: 30px;
            height: 30px;
            font-size: 30px;
            margin-left: 20px;
            margin-bottom: 20px;

            &:after {
                position: absolute;
                content: '';
                top: 5px;
                right: 4px;
                width: 9px;
                height: 9px;
                border-top: solid 2px rgba(255,255,255, .6);
                border-right: solid 2px rgba(255,255,255, .6);
            }
        }
    }

    @media (min-width: 768px) {
        display: flex;
        background-color:rgb(0 0 0 / 70%);
        width: 369px;
        height: 247px;

        .image-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 220px;
            height: 248px;
        }

        .right-text-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 38px;
            padding-bottom: 22px;
            padding-left: 10px;

            .top-texts  {
                display: flex;
                height: 100%;
                flex-direction: column;
                padding-left: 10px;
                text-transform: uppercase;
                color: #C4C4C4;

                .compass-title {
                    font-size: 1.1rem;
                    padding: 0;
                    margin: 0;
                    letter-spacing: .6px;
                    margin-bottom: 8px;
                }

                .compass-subtitle {
                    font-size: .8rem;
                    padding: 0;
                    margin: 0;
                    line-height: 9px;
                    letter-spacing: .9px;
                }
            }

            .bottom-texts {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: flex-end;
                padding-left: 10px;
                color: #C4C4C4;

                .compass-title {
                    font-size: 1rem;
                    padding: 0;
                    margin: 0;
                    letter-spacing: .5px;
                    text-transform: uppercase;
                }

                .compass-subtitle {
                    font-size: .7rem;
                    padding: 0;
                    margin: 0;
                    line-height: 9px;
                    letter-spacing: .9px;
                }
            }
        }

        .button-maximizar {
            position: absolute;
            z-index: 3;
            cursor: pointer;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            font-size: 30px;
            margin-left: 20px;
            margin-bottom: 20px;

            &:after {
                position: absolute;
                content: '';
                top: 9px;
                right: 9px;
                width: 11px;
                height: 11px;
                border-top: solid 2px rgba(255,255,255, .6);
                border-right: solid 2px rgba(255,255,255, .6);
            }
        }
    }
`
export const CompassPointer = styled.img`
    z-index:3;
    position: absolute; 
    @media (max-width: 768px) {
        left: 0; 
        top: 0;
    }
    @media (min-width: 768px) {
        left: calc(50% - 32px);
        top: calc(50% - 32px);
    }
    width: 65px; 
    height: 65px;
`
export const MapBackground = styled.div`
    z-index:2;
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0.02;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    &:hover{
        opacity: 0.2
    }
`
export const MapDiv = styled.div`
    position: fixed;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    flex-flow:nowrap;
    margin:30px; 
    color:#FFF; 
    font-family:'DIN2014';
    bottom: 0;
    z-index: 11;

    &.expanded {
        z-index: 11;
        @media (max-width: 768px) {
            margin:12px; 
        }
        @media (min-width: 768px) {
            overflow: hidden;
            height: 100vh;
            min-height: 570px;
            margin: 0 85px;
        }

        @media (min-width: 1400px) {
            min-height: 780px;
        }
    }

    #left{
        height:100%;
        width:100%;
        @media (max-width: 767px) {
            .compass-web {
                display: none;
            }
        }

        @media (min-width: 768px) {
            .compass-mobile {
                display: none;
            }
        }
    }

    #right{
        height:100%;
        width:120px;
        width:250px;
        height:150px;
        bottom: 16px;
        left: 16px;
        z-index: 1; 
        display: flex; 
        flex-direction: column;
        justify-content: space-between;
    }
`