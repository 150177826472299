import { ContactActionType } from '../../types'

export const sendContactAPI = data => {
  return {
    type: ContactActionType.SEND_API,
    payload: data
  }
}

export const sendContactAPISuccess = data => {
  return {
    type: ContactActionType.SEND_API_SUCCESS,
    payload: data
  }
}

export const sendContactAPIFailure = error => {
  return {
    type: ContactActionType.SEND_API_FAILURE,
    payload: error
  }
}
