import React from 'react'

const HotspotProductIcon = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <defs>
        <clipPath id="clip-Artboard_2">
          <rect width="26" height="26"/>
        </clipPath>
      </defs>
      <g id="Artboard_2" data-name="Artboard – 2" clipPath="url(#clip-Artboard_2)">
        <rect width="26" height="26" fill="#fff" className="border" />
        <rect id="Background" width="22" height="22" transform="translate(2 2)" fill="#2a2a2a" opacity="0.8" />
        <path id="frame" d="M949.959,529.958h20.084v20.083H949.959ZM949,551h22V529H949Z" transform="translate(-947 -526.998)" fill="#e9e9e9"/>
        <path id="k2" d="M956.152,544.913h2.719V534.108h-2.719Z" transform="translate(-947 -526.998)" fill="#e9e9e9"/>
        <path id="k1" d="M964.737,535.692l-1.924-1.922-3.847,3.842,1.923,1.921-1.923,1.922,3.8,3.795,1.922-1.922-3.8-3.795Z" transform="translate(-947 -526.998)" fill="#e9e9e9"/>
      </g>
    </svg>
  )
})

export {HotspotProductIcon}