import styled from 'styled-components';

export const StyledLi = styled.li`
  color: #fff;
  font-family:DIN2014;
  font-size: ${props => props.mobile ? 1.2 : 1.4}em;
  margin-bottom: 40px;
  list-style: none;
  svg { 
    margin-right: 16px;
    max-width: 30px;
  }
`;

export const StyledTitle = styled.h1`
  color: #fff;
  margin-bottom: 40px;
  font-size: ${props => props.mobile ? 3 : 4.5}rem;
  letter-spacing: ${props => props.mobile ? .23 : .05}em;
  text-align: ${props => props.mobile ? 'center' : '' };
`;

export const StyledUl = styled.ul`
  margin-left: 0;
`;
