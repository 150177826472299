import React from 'react'
import { isMobile, mobileClass } from '../../../utils'
import { BackIcon, CloseIcon, InstagramIcon, LinkedinIcon, PinterestIcon } from '../..'
import { Checkbox, Input, Drawer } from 'antd'
import { sendContactAPI } from '../../../effects/actions'
import { connect } from 'react-redux'

import { SecondaryButton,
  MenuItem,
  ActionButtonLeft,
  MainContent,
  MenuHeader,
  SocialMediaButtons,
  SocialMediaDiv,
  StyledMenuOptions,
  StyledMenu,
  MenuFooter } from './menu.styled';

const MenuComponent = React.memo(({ closeMenu, active, visitor, sendContactAPI }) => {
  return (
    <Drawer 
      id="drawer" 
      className={mobileClass("")} 
      placement="left" 
      closable={true} 
      width={isMobile() ? "100%" : "40%"} 
      height="100%" closeIcon={null} 
      onClose={closeMenu} visible={active}>
      {active && <MenuContent closeMenu={closeMenu} visitor={visitor} sendContactRequest={sendContactAPI} />}
    </Drawer>
  );
})

const MenuContent = React.memo(({ closeMenu, visitor, sendContactRequest }) => {
  const [menu, setMenu] = React.useState()
  const goBackMenu = () => setMenu()
  return (
    <StyledMenu>
      <MenuHeader className={mobileClass("")}>
        {!menu && (
          <ActionButtonLeft onClick={closeMenu} left={0} border={true} className={mobileClass("icon action-menu")}>
            <CloseIcon fill='#777777' stroke='#777777' />
          </ActionButtonLeft>
        )}
        {menu && (
          <ActionButtonLeft onClick={goBackMenu} left={0} className={mobileClass("icon action-menu")}>
            <BackIcon border={true} />
          </ActionButtonLeft>
        )}

        <SocialMediaDiv className={mobileClass("")}>
          <SocialMediaButtons right={2} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.instagram.com/laskasas/", "_blank")}>
            <InstagramIcon size={isMobile() ? 17 : 20}/>
          </SocialMediaButtons>
          <SocialMediaButtons right={1} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.pinterest.pt/laskasas/", "_blank")}>
            <PinterestIcon size={isMobile() ? 17 : 20}/>
          </SocialMediaButtons>
          <SocialMediaButtons right={0} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.linkedin.com/company/laskasas/", "_blank")}>
            <LinkedinIcon size={isMobile() ? 17 : 20}/>
          </SocialMediaButtons>
        </SocialMediaDiv>
      </MenuHeader>

      <MainContent className={mobileClass("")}>
        <StyledMenuOptions className={mobileClass("")}>
          <MenuOptions menu={menu} setMenu={setMenu} visitor={visitor} sendContactRequest={sendContactRequest} />
        </StyledMenuOptions>
      </MainContent>

      <MenuFooter className={mobileClass("text din size-12 text-center d-flex flex-column justify-content-center")}>
        <div className="mb-2 pointer">
          <span onClick={() => window.open('https://laskasas.com/privacy-policy/?utm_source=virtual-tour-2021&utm_medium=online&utm_campaign=laskasas-immersive-experience', '_blank')}>
            Terms and Conditions | © 2020 Laskasas. All rights reserved.
          </span>
        </div>
        <div className="mb-2 pointer">
          <span onClick={() => window.open('https://vr360.pt', '_blank')}>POWERED BY VR360</span>
        </div>
      </MenuFooter>
    </StyledMenu>
  )
})

const MenuOptions = React.memo(({ menu, setMenu, visitor, sendContactRequest }) => {
  const [contactData, setContactData] = React.useState({
    name: visitor?.name || "",
    email: visitor?.email || "",
    position: visitor?.position || "",
    phone_number: "",
    country: "",
    company: "",
    message: "",
  })

  const onChange = event => {
    const { name, value } = event.target;
    setContactData(previousState => ({
      ...previousState,
      [name]: value.trim()
    }))
  }

  switch (menu) {
    case 'company':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('company')}>The Company Story</MenuItem>
          <div className="content">
            <p>Portuguese furniture design brand renowned for creating handmade furniture, upholstery and metalwork pieces, for residential and commercial projects. </p>
            <p>We strive to honour and elevate Portuguese heritage of craftsmanship.</p>
            <p>Everything is carefully handmade in our warehouse.</p>
            <p>With long-lasting expertise, Laskasas pieces are available worldwide.</p>
            <p>The contemporary reinterpretation has influences of the Scandinavian functionality, the Italian luxury and the French glamour. Comfort is so important that it hasn't a nationality or particular roots. </p>
            <p><b>The Acts: what do we stand for?</b></p>
            <p>Laskasas escapes from furniture conventions to embrace a different kind of silhouettes and materials combinations. In our warehouse and headquarter, we seek to redefine what it means to be a Portuguese furniture brand.</p>
            <p>Far from being static, we believe in functionality, forms, comfort and balance.</p>
            <p>That is what we stand for. And YOU?</p>
            <br />
            <p style={{ fontSize: 16, textAlign: "right" }}>“True furniture icons don’t create themselves. Laskasas classics are born from our clients' distinctive choices”.</p>
            <p style={{ fontSize: 12, textAlign: "right" }}>- Celso Lascasas, founder & CEO</p>
          </div>
        </React.Fragment>
      )
    case 'catalogue':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('catalogue')}>2021 Catalogue</MenuItem>
          <div className="content">
            <p>Our luxury and extensive selection of furniture, lighting, upholstery and home accessories is designed to meet your needs for your specific projects. Tables, chairs, storage, sofas, armchairs, beds, mirrors or lamps.</p>
            <p>Explore our diverse collection and create the look you want. </p>
            <p>Create new worlds through Portuguese furniture heritage.</p>
            <br />
          </div>
          <SecondaryButton block href="https://laskasas.com/the-catalogue-library" target="_blank">DOWNLOAD</SecondaryButton>
        </React.Fragment>
      )
    case 'materials':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('materials')}>Materials & Finishes</MenuItem>
          <div className="content">
            <p>We are a furniture design brand renowned for tailor-made options, handmade upholstery and unmatched combinations of non-conventional materials with long-lasting pieces.</p>
            <p>The custom-made options and the bespoke service are key-pieces of Laskasas business, allowing you to select the exact materials and finishes that suit your residential and commercial project.</p>
            <p>Our designs. Your pieces.</p>
            <p>Exclusively yours.</p>
            <br />
          </div>
          <SecondaryButton block href="https://laskasas.com/materials-and-finishes/" target="_blank">EXPLORE PREMIUM COLLECTION</SecondaryButton>
        </React.Fragment>
      )
    case 'showrooms':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('showrooms')}>Showrooms & Stores</MenuItem>
            <div className="content">
              <p> Find a showroom near you and experience our furniture collections to the fullest:</p>
              <br />
              <p>Countries where we are:</p>
              <p>- Portugal</p>
              <p>- Spain</p>
              <p>- Dominican Republic</p>
              <p>- UK</p>
              <p>- Italy</p>
              <p>- Russia</p>
              <p>- United Arab Emirates (Dubai)</p>
              <p>- Republic of Panama</p>
              <p>- Slovakia</p>
              <br />
            </div>
          <SecondaryButton block href="https://laskasas.com/contacts/?utm_source=virtual-tour-2021&utm_medium=online&utm_campaign=laskasas-immersive-experience#showrooms" target="_blank">EXPLORE STORES</SecondaryButton>
        </React.Fragment>
      )
    case 'contacts':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('contacts')}>Contacts</MenuItem>
          <Input onChange={onChange} name="name" className="mb-2" width="100%" placeholder="Name" value={contactData.name} />
          <Input onChange={onChange} name="email" className="mb-2" width="100%" placeholder="E-mail" value={contactData.email} />
          <Input onChange={onChange} name="phone_number" className="mb-2" width="100%" placeholder="Phone Number" />
          <Input onChange={onChange} name="country" className="mb-2" width="100%" placeholder="Country*" />
          <Input onChange={onChange} name="position" className="mb-2" width="100%" placeholder="Occupation*" value={contactData.position} />
          <Input onChange={onChange} name="company" className="mb-2" width="100%" placeholder="Company" />
          <Input.TextArea width="100%" onChange={onChange} rows={4} name="message" placeholder="Your Message" />
          <br />
          <div className="mt-2 mb-5">
            <Checkbox>I agree to the terms and conditions.</Checkbox>
          </div>
          <SecondaryButton block onClick={() => sendContactRequest(contactData)}>SEND</SecondaryButton>
        </React.Fragment>
      )
    default: return (
      <React.Fragment>
        <MenuItem className={mobileClass("")} onClick={() => setMenu('company')}>The Company Story</MenuItem>
        <MenuItem className={mobileClass("")} onClick={() => setMenu('catalogue')}>2021 Catalogue</MenuItem>
        <MenuItem className={mobileClass("")} onClick={() => setMenu('materials')}>Materials & Finishes</MenuItem>
        <MenuItem className={mobileClass("")} onClick={() => setMenu('showrooms')}>Showrooms & Stores</MenuItem>
        <MenuItem className={mobileClass("")} onClick={() => setMenu('contacts')}>Contacts</MenuItem>
      </React.Fragment>
    )
  }
})

const mapStateToProps = ({ visitorAPI }) => {
  const { visitor } = visitorAPI

  return {
    visitor
  }
}

export default connect(mapStateToProps, {
  sendContactAPI
})(React.memo(MenuComponent))
