import styled from 'styled-components';

const StyledPlaylistDiv = styled.div`
  height: 65px;
  width: 300px;
  display: grid;
  grid-template-columns: 25% 45% 30%;
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  position: absolute;
  right: -2.3em;
  top: ${p => p.changePosition ? '-1.4' :'2.4'}em;
  transition: .3s;
  cursor: pointer;
  @media (max-width: 768px){
    right: 0.7em;
  }
`;

const ActionButton = styled.div`
  position: absolute;
  width: 34px;
  height: 34px;
  cursor:pointer;
  display: ${p => (p.active ? 'flex' : 'none')}; 
  align-items: center; 
  justify-content: center;
  right: ${p => (p.right ? "-30px" : 'unset')};
  left: ${p => (p.left ? "0" : 'unset')}; 
  top:${p => (p.position * 46)}px; 
  
  @media (max-width: 1400px) {
    width: 30px;
    height: 30px;
    top:${p => (p.position * 40)}px; 
  }

  > svg {
    width: 35px;
    ${p => p.isFavorite && (
      `
        -webkit-animation: scale 2s infinite  normal ease-out;
        animation: scale 2s infinite  normal ease-out;
      `
    )}
  }
  &.mobile{
    margin-left: 15px;
    width:25px; 
    height:25px;
    top:${p => p.position * 35}px;
    right: 15px;
  }`;

const StyledButtonDiv = styled.div`
  border: 1px solid ${p => p.fill};
  height: 34px;
  width: 34px;
  @media (max-width: 1400px) {height: 30px; width: 30px}
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  grid-column: 3;
  grid-row: 1 / 2;
  margin-top: 1.3em;
  margin-left: 3.7em;
  transition: .5s;

  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
    top: 35px;
    right: 15px;
  }

  &:hover {
    border: 1px solid ${p => p.fillHover};
  }
`;

const MainLogo = styled.div`
  position: absolute; 
  height: 24px; 
  top: 6px; 
  &.mobile{
    position:relative;
    top:0;
    height:100%;
  };
  img{position:absolute}
`;

const Header = styled.header`
  height: 34px;
  z-index: 10;
  position:fixed;
  top: 50px;
  left: 35px;
  right:70px;
  bottom: 0;
  &.mobile{
    width: 100%;
    height: 25px;
    top: 15px;
    left:0;
    right: 0;
  }

  .icon {
  transition:0.5s all;
  img {
    width: 100%;
    padding: 0;
    position:absolute
  }
  opacity: 0.9;
  border: 1px solid ${p => p.white ? '#6b6b6b' : '#b7b7b7'};
  &:hover{
    opacity:1;
    transition:0.5s all;
    border:1px solid ${p => p.white ? '#b7b7b7' : '#6b6b6b'};
  }
}

.icon-link {
  border: none;
  background: #00000012;
  &:hover{
    border: none;
    background: #000000b0
  }

  @media (max-width: 767px) {
    border: none;
    background: #00000012;
    &:hover{
      border: none;
      background: #00000012;
    }
  }
}
`;

const StyledDivSharedIcons = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  height: 2.6em;
  width: 12em;
  right: 0.5em;
  top: ${p => !p.changePosition ? '10.55' : '21.4'}em;
  &:hover {
    color: ${p => !p.changePosition ? '#fff' : '#000' };
  }
`;

const StyledSharedIcons = styled.div`
  font-size: 19px;
  color: rgba(255, 255, 255, .5);
  width: 35px;
  height: 35px;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${p => (p.right ? "0" : 'unset')};
  left: ${p => (p.left ? "0" : 'unset')};
  top:${p => (p.position * 60)}px;
  transition: .5s;
  &:hover {
    color: rgba(255, 255, 255, 1);
  };
  > svg {
    width: 35px
  }
  &.mobile{
    width:25px;
    height:25px;
    top:${p => (p.position * 35)}px
  }`;

const StyledArrow = styled.div`
  grid-column: 3;
  grid-row: 1 / -1;
  display: ${p=>p.show ? "grid": "none"};
  align-items: center;
  font-size: 1.5em;
  padding-left: 0.6em;
  padding-top: 5px;
  @media (max-width: 768px) {
    svg{
      position: absolute;
      right: 28px;
      top: 26px;
      fill: #fff;
    }
  }
`;

export { StyledSharedIcons, StyledArrow, Header, MainLogo, StyledButtonDiv, ActionButton, StyledPlaylistDiv, StyledDivSharedIcons }
