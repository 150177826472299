import React from 'react'
import { Drawer } from 'antd'
import { isMobile } from '../../utils'
import styled from 'styled-components'
import Product from './product'
import { NoResults } from './no-results'
import { CloseIcon } from '../icons'
import { connect } from 'react-redux'

const StyledDrawer = styled(Drawer)`
  overflow-y: scroll;

  .ant-drawer-content {
    background-color: #dcdada;
  }

  .ant-drawer-body {
    margin-top: 100px;
  }



  .ant-drawer-close {
    outline: none;
    opacity: 0.9;
    border: 1px solid #b7b7b7;
    margin-left: 15px;
    transform: scale(0.4);
    padding: 0;

    svg {
      height: 80px;
      width: 80px;
    }

    &:hover{
      opacity:1;
      cursor: pointer;
      transition:0.3s all;
      svg{
        rect{
          fill: #e3e3e3 !important;
        }
        path{
          stroke: #121212 !important;
          cursor: pointer;
        }
        
      }
    }
  }
  
`

const renderFavorireProduct = (favorite, i) => {
  return (
    <Product
      key={i}
      id={favorite.id}
      name={favorite.fullname}
      image={`${favorite.id}_2.png`}
      description={favorite.product}
      dimensions={favorite.dimensions}
    />
  )
}

const FavoritesComponent = props => {

  return (
    <StyledDrawer
      closeIcon={<CloseIcon fill={"transparent"} stroke="#ababab"/>}
      placement="right"
      closable={true}
      keyboard={true}
      mask={true}
      maskClosable={true}
      visible={props.active}
      headerStyle={{backgroundColor: '#dcdada'}}
      onClose={props.onClose}
      width={isMobile() ? "100%" : "40%"}>

      {
        props.favorites.length > 0 ? props.favorites.map(renderFavorireProduct) : <NoResults />
      }
    </StyledDrawer>
  )
}

export default connect(state => {
  return { favorites: state.favorites.products }
})(FavoritesComponent)
