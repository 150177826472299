export const BlueDot = require('./icons/bluedot.png')
export const Walkspot = require('./icons/walkspot.png')
export const Preview = require('./models/2021/preview.jpg')
export const AudioOnDark = require('./icons/audio-on-dark.gif')
export const AudioOn = require('./icons/audio-on.gif')

export const MusicAct1 = require('./musics/act01.mpeg')
export const MusicAct2 = require('./musics/act02.mpeg')
export const MusicAct3 = require('./musics/act03.mpeg')
export const MusicAct4 = require('./musics/act04.mpeg')
export const MusicAct5 = require('./musics/act05.mpeg')
