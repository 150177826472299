import { AppActionType } from './../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  currentAct: null,
  currentActSong: null,
  muted: false,
  networkSpeed: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case AppActionType.UPDATE_CURRENT_ACT:
      return {
        ...state,
        currentAct: action.payload
      }

    case AppActionType.UPDATE_CURRENT_ACT_SONG:
      return {
        ...state,
        currentActSong: action.payload
      }

    case AppActionType.UPDATE_MUTED:
      return {
        ...state,
        muted: action.payload === null ? !state.muted : action.payload
      }
    case AppActionType.UPDATE_NETWORK_SPEED:
      return {
        ...state,
        networkSpeed: action.payload
      }
    default: return { ...state }
  }
}
