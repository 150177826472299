import { AppActionType } from '../types'

export const updateCurrentAct = (act) => {
  return {
    type: AppActionType.UPDATE_CURRENT_ACT,
    payload: act
  }
}

export const updateCurrentActSong = (song) => {
  return {
    type: AppActionType.UPDATE_CURRENT_ACT_SONG,
    payload: song
  }
}

export const updateMuted = (muted = null) => {
  return {
    type: AppActionType.UPDATE_MUTED,
    payload: muted
  }
}

export const updateNetworkSpeed = (speed) => {
  return {
    type: AppActionType.UPDATE_NETWORK_SPEED,
    payload: speed
  }
}