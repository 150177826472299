import React from 'react';
import PropTypes from 'prop-types';

const MenuZoom = React.memo(({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g transform="translate(-1816 -348)">
            <path d="M20.682,322.074h4.573a.2.2,0,0,0,.2-.2V317.3" transform="translate(1861.636 688.875) rotate(180)" fill="none" stroke={fill} strokeWidth="2" />
            <path d="M14.318,307.954H9.746a.2.2,0,0,0-.2.2v4.573" transform="translate(1839.364 670.182) rotate(180)" fill="none" stroke={fill} strokeWidth="2" />
        </g>
    </svg>
));
MenuZoom.propTypes = { fill: PropTypes.string };
MenuZoom.defaultProps = { fill: "#fff" };
export { MenuZoom };
