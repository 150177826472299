import {
  all, call, fork, put, takeEvery
} from 'redux-saga/effects'

import {
  sendContactAPISuccess,
  sendContactAPIFailure
} from './../../actions'
import {
  ContactActionType,
} from './../../types'
import { API } from './../../../utils/api'

const sendContactRequest = async (data) => API.post('contact', data)
function* sendContact({ payload }) {
  try {
    const request = yield call(sendContactRequest, payload)
    yield put(sendContactAPISuccess(request))
  } catch (error) {
    yield put(sendContactAPIFailure(error))
  }
}

export function* sendContactAPI() {
  yield takeEvery(ContactActionType.SEND_API, sendContact)
}

export default function* rootSaga() {
  yield all([
    fork(sendContactAPI),
  ])
}
