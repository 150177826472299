/* eslint-disable no-unused-expressions */
import React from 'react'
// import { Tooltip } from 'antd'
// const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap0 = React.memo(({ hoverProduct, height, width, setActiveProduct, currentProduct, history = {} }) => {
    // const [hovered, setHover] = React.useState("")
    // const findProduct = (id) => catalog.find(x => x.id === id)
    // const hoverClass = (id) => (hoverProduct && hovered === id ? (`map-product-hover ${id}`) : "")
    // const isHovered = (id) => (hovered === id)
    // const clickIn = (id) => { setHover(); if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    // const hoverIn = (id) => { if (hovered === id) return; setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 178.745 244.096">
            <g id="Group_511" data-name="Group 511" transform="translate(-23.943 -29.304)">
                <path d="M-10580.272,1290.005h-5.251" transform="translate(10782.961 -1175.575)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10645.849,1119.537V1080.26h-40.378" transform="translate(10843.286 -1049.456)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-11009.922,1503.417h-66.231" transform="translate(11207.359 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-11001.752,1395.033l-.76-72.549" transform="translate(11033.237 -1195.557)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-11001.752,1392.634l-.76-70.15" transform="translate(11033.237 -1291.59)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10668.111,1503.418v7.25" transform="translate(10832.538 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10922.947,1503.418v6.868" transform="translate(10985.469 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10584.493,1399.243v48.639" transform="translate(10782.344 -1241.538)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10584.493,1399.243v38.569" transform="translate(10782.344 -1305.153)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10802.936,1081.429h-39.121" transform="translate(10936.873 -1050.156)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-10794.367,1081.429h-47.689" transform="translate(10866 -1050.156)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path d="M-11003.563,1503.417h-72.08" transform="translate(11101.779 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <g id="Rectangle_921" data-name="Rectangle 921" transform="translate(169.716 126.681)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="25.843" height="48.646" stroke="none" />
                    <rect x="0.5" y="0.5" width="24.843" height="47.646" fill="none" />
                </g>
                <g id="Rectangle_922" data-name="Rectangle 922" transform="translate(141.705 101.116)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="26.603" height="48.646" stroke="none" />
                    <rect x="0.5" y="0.5" width="25.603" height="47.646" fill="none" />
                </g>
                <g id="Rectangle_923" data-name="Rectangle 923" transform="translate(169.716 64.181)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="25.843" height="48.646" stroke="none" />
                    <rect x="0.5" y="0.5" width="24.843" height="47.646" fill="none" />
                </g>
                <g id="Rectangle_932" data-name="Rectangle 932" transform="translate(33.387 91.317)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="25.843" height="48.646" stroke="none" />
                    <rect x="0.5" y="0.5" width="24.843" height="47.646" fill="none" />
                </g>
                <g id="Rectangle_931" data-name="Rectangle 931" transform="translate(61.386 105.654)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="25.843" height="45.605" stroke="none" />
                    <rect x="0.5" y="0.5" width="24.843" height="44.605" fill="none" />
                </g>
                <g id="Rectangle_927" data-name="Rectangle 927" transform="translate(82.571 60.45)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="48.646" height="26.603" stroke="none" />
                    <rect x="0.5" y="0.5" width="47.646" height="25.603" fill="none" />
                </g>
                <g id="visible-area" className="visible-area" transform="translate(94.71 142.733)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="36.484" height="23.563" stroke="none" />
                    <rect x="0.5" y="0.5" width="35.484" height="22.563" fill="none" />
                </g>
                <g id="Rectangle_924" data-name="Rectangle 924" transform="translate(102.286 168.343)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="36.484" height="23.563" stroke="none" />
                    <rect x="0.5" y="0.5" width="35.484" height="22.563" fill="none" />
                </g>
                <g id="Rectangle_925" data-name="Rectangle 925" transform="translate(89.383 89.8)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="50.926" height="50.926" stroke="none" />
                    <rect x="0.5" y="0.5" width="49.926" height="49.926" fill="none" />
                </g>
                <g id="Rectangle_926" data-name="Rectangle 926" transform="translate(112.861 33.288)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="50.166" height="25.843" stroke="none" />
                    <rect x="0.5" y="0.5" width="49.166" height="24.843" fill="none" />
                </g>
                <g id="Rectangle_930" data-name="Rectangle 930" transform="translate(55.282 33.288)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="48.646" height="25.843" stroke="none" />
                    <rect x="0.5" y="0.5" width="47.646" height="24.843" fill="none" />
                </g>
                <g id="Rectangle_929" data-name="Rectangle 929" transform="translate(106.107 107.279)" fill="none" stroke="#c4c4c4" strokeWidth="0.75">
                    <rect width="16.722" height="16.722" stroke="none" />
                    <rect x="0.5" y="0.5" width="15.722" height="15.722" fill="none" />
                </g>
                <rect id="book" width="5.614" height="7.618" transform="translate(111.403 112.62) rotate(-11)" fill="#7a303f" />
                <path id="Path_1232" data-name="Path 1232" d="M-10668.111,1503.418v73.924" transform="translate(10832.539 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
                <path id="Path_1237" data-name="Path 1237" d="M-10922.947,1503.418v73.542" transform="translate(10985.47 -1303.941)" fill="none" stroke="#7a303f" strokeWidth="2" />
            </g>
        </svg>
    )
})

export { ActMap0 }