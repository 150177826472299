import React from 'react'
import PropTypes from 'prop-types'
const MenuExpand = React.memo(({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Group_419" data-name="Group 419" transform="translate(-460 -748.998)">
            <rect width="30" height="30" transform="translate(460 748.998)" opacity="0" />
            <path d="M42,41H52V51" transform="translate(428 717.998)" fill="none" stroke={fill} strokeWidth="1" />
        </g>
    </svg>))
MenuExpand.propTypes = { fill: PropTypes.string }
MenuExpand.defaultProps = { fill: "#fff" }
export { MenuExpand }
