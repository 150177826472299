import React from 'react';


const LogoIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
            <rect id="Rectangle_257" data-name="Rectangle 257" width="38" height="38" fill="none" opacity="0.7"/>
            <path id="Path_173" data-name="Path 173" d="M343.05,296.193h4.5l0-17.9H343.05Z" transform="translate(-330.616 -268.411)" fill="#f9f9f9"/>
            <path id="Path_174" data-name="Path 174" d="M484.749,265.261l-3.19-3.168-6.374,6.333,3.186,3.164-3.186,3.168,6.3,6.254,3.185-3.167-6.3-6.255Z" transform="translate(-458.114 -252.723)" fill="#f9f9f9"/>
            <path id="Path_1447" data-name="Path 1447" d="M1.631,1.628H35.8V35.8H1.631ZM0,37.426H37.429V0H0Z" transform="translate(0.365 0.117)" fill="#f9f9f9" opacity="0.1"/>
        </svg>
    )
})

const HeartIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="37.429" height="37.429" viewBox="0 0 37.429 37.429">
            <path id="Path_1453" data-name="Path 1453" d="M28.147,15.213c-.193,3.811-4.01,6.848-7.441,9.685-1.808,1.5-2.974,1.487-4.789,0C12.509,22.106,9.151,19.039,9,15.1c-.184-4.955,5.8-6.983,9.2-2.941.373.506.373.506.742,0C22.336,8.093,28.4,10.149,28.147,15.213Z" transform="translate(0.669 0.11)" fill="#f9f9f9" fillRule="evenodd"/>
            <path id="Path_1454" data-name="Path 1454" d="M1.631,1.628H35.8V35.8H1.631ZM0,37.426H37.429V0H0Z" transform="translate(0 0.003)" fill="#f9f9f9" opacity="0.1"/>
        </svg>
    )
})

const ChatIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="36.709" height="39.78" viewBox="0 0 36.709 39.78">
            <g id="Path_1451" data-name="Path 1451" fill="rgba(42,42,42,0.8)">
                <path d="M 35.70951461791992 35.70928192138672 L 1.00000536441803 35.70928192138672 L 1.00000536441803 1.000002861022949 L 35.70951461791992 1.000002861022949 L 35.70951461791992 35.70928192138672 Z" stroke="none"/>
                <path d="M 1.999996185302734 2.000003814697266 L 1.999996185302734 34.70928192138672 L 34.70950698852539 34.70928192138672 L 34.70950698852539 2.000003814697266 L 1.999996185302734 2.000003814697266 M -3.814697265625e-06 3.814697265625e-06 L 36.70950698852539 3.814697265625e-06 L 36.70950698852539 36.70928192138672 L -3.814697265625e-06 36.70928192138672 L -3.814697265625e-06 3.814697265625e-06 Z" stroke="none" fill="#f9f9f9"/>
            </g>
            <g id="Path_1452" data-name="Path 1452" transform="translate(22.026 39.78) rotate(180)" fill="none">
                <path d="M3.671,0,7.342,3.671H0Z" stroke="none"/>
                <path d="M 3.670950889587402 1.41420841217041 L 2.414224624633789 2.670924186706543 L 4.927677154541016 2.670924186706543 L 3.670950889587402 1.41420841217041 M 3.670950889587402 4.291534423828125e-06 L 7.341900825500488 3.670924186706543 L 9.5367431640625e-07 3.670924186706543 L 3.670950889587402 4.291534423828125e-06 Z" stroke="none" fill="#f9f9f9"/>
            </g>
            <path id="Path_709" data-name="Path 709" d="M4.037,2.666A9.292,9.292,0,0,1,10.093.625a9.292,9.292,0,0,1,6.056,2.041,6.075,6.075,0,0,1,0,9.837,9.292,9.292,0,0,1-6.056,2.041,10.171,10.171,0,0,1-3.58-.636A7.487,7.487,0,0,1,1.8,15.615a.222.222,0,0,1-.134-.05.332.332,0,0,1-.1-.117.243.243,0,0,1,.05-.284q.117-.117.4-.468a9.542,9.542,0,0,0,.753-1.171,6.746,6.746,0,0,0,.669-1.556A6.026,6.026,0,0,1,1.527,7.584,6.149,6.149,0,0,1,4.037,2.666Z" transform="translate(8.907 9.929)" fill="#f9f9f9"/>
        </svg>
    )
})

export {
    ChatIcon, HeartIcon, LogoIcon
}