import React from 'react'
import { Tooltip } from 'antd'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap2 = React.memo(({ hoverProduct, height, width, setActiveProduct, currentProduct, history = {} }) => {
  const [hovered, setHover] = React.useState("")
  const findProduct = (id) => catalog.find(x => x.id === id)
  const hoverClass = (id) => (hoverProduct && hovered === id ? (`map-product-hover ${id}`) : "")
  const isHovered = (id) => (hovered === id)
  const clickIn = (id) => { setHover(); if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
  const hoverIn = (id) => { if (hovered === id) return; setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 187.977 173.247">
      <g id="Group_514" transform="translate(-31.071 -13.567)">
        <path className="not-interact" d="M-11002.508,1328.68v-6.2" transform="translate(11160.176 -1141.946)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M-11261.511,655.83h-140.079v127.5h43.042" transform="translate(11434.161 -602.507)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M-10831.344,1081.516h-11.1" transform="translate(11050.392 -901.025)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M-10787.212,1080.486l-54.936.032" transform="translate(10976.934 -900.006)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M-10996.1,627.153v-98.39h-79.323" transform="translate(11202.167 -513.695)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M-19959.758,5881.764v36.819" transform="translate(20093.504 -5866.037)" fill="none" stroke="#fff" strokeWidth="0.75" opacity="0.2" />
        <path className="not-interact" d="M-19832.008,6444.29v-4.6h35.66" transform="translate(20009.074 -6257.476)" fill="none" stroke="#c4c4c4" strokeWidth="1" />

        <rect id="visible-area" width="89.853" height="95.793" transform="translate(53.8 69.165)" fill="#2a2a2a" opacity="0.5" className="visible-area" />
        <rect id="Rectangle_937" transform="translate(71.353 144.166)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="18.307" height="18.307" rx="2.5" fill="#FFFFFF00" />

        <g transform="translate(95, 140)" id="visit-living-room/3" onClick={() => clickIn("visit-living-room/3")} onPointerOver={() => hoverIn("visit-living-room/3")} onPointerEnter={() => hoverIn("visit-living-room/3")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-living-room/3")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <g transform="translate(110, 95)" id="visit-living-room/2" onClick={() => clickIn("visit-living-room/2")} onPointerOver={() => hoverIn("visit-living-room/2")} onPointerEnter={() => hoverIn("visit-living-room/2")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-living-room/2")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <g transform="translate(60, 105)" id="visit-living-room/1" onClick={() => clickIn("visit-living-room/1")} onPointerOver={() => hoverIn("visit-living-room/1")} onPointerEnter={() => hoverIn("visit-living-room/1")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-living-room/1")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <rect className={hoverClass("act2-janis-suspension")} onClick={() => hoverIn("act2-janis-suspension")} onPointerOver={() => hoverIn("act2-janis-suspension")} onPointerEnter={() => hoverIn("act2-janis-suspension")} transform="translate(33.008 87.662)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="7.168" height="58.407" fill="#FFFFFF00" />
        <rect className={hoverClass("act2-brown-tv")} onClick={() => hoverIn("act2-brown-tv")} onPointerOver={() => hoverIn("act2-brown-tv")} onPointerEnter={() => hoverIn("act2-brown-tv")} transform="translate(41.919 95.084)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="8.654" height="42.812" rx="4.327" fill="#FFFFFF00" />
        <ellipse className={hoverClass("act2-marcel-table")} onClick={() => hoverIn("act2-marcel-table")} onPointerOver={() => hoverIn("act2-marcel-table")} onPointerEnter={() => hoverIn("act2-marcel-table")} transform="translate(44 100)" stroke="#fff" strokeWidth="0.75" cx="3.342" cy="3.342" rx="2.842" ry="2.842" fill="#FFFFFF00" id="act2-cap-side" />

        <rect className={hoverClass("act2-jackson-wall-lamp")} onClick={() => hoverIn("act2-jackson-wall-lamp")} onPointerOver={() => hoverIn("act2-jackson-wall-lamp")} onPointerEnter={() => hoverIn("act2-jackson-wall-lamp")} transform="translate(129.499 54.912)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="41.327" height="5.683" fill="#FFFFFF00" />
        <ellipse className={hoverClass("act2-lyssa-coffee")} onClick={() => hoverIn("act2-lyssa-coffee")} onPointerOver={() => hoverIn("act2-lyssa-coffee")} onPointerEnter={() => hoverIn("act2-lyssa-coffee")} cx="11.139" cy="10.767" rx="11.139" ry="10.767" stroke="FFFFFF00" transform="translate(73.56 104.732)" fill="#FFFFFF00" strokeWidth="0.75" />
        <ellipse className={hoverClass("act2-lyssa-coffee")} onClick={() => hoverIn("act2-lyssa-coffee")} onPointerOver={() => hoverIn("act2-lyssa-coffee")} onPointerEnter={() => hoverIn("act2-lyssa-coffee")} cx="8.54" cy="8.54" rx="8.54" ry="8.54" stroke="FFFFFF00" transform="translate(94.217 115.881)" fill="#FFFFFF00" strokeWidth="0.75" />
        <ellipse className={hoverClass("act2-lyssa-side")} onClick={() => hoverIn("act2-lyssa-side")} onPointerOver={() => hoverIn("act2-lyssa-side")} onPointerEnter={() => hoverIn("act2-lyssa-side")} cx="5.542" cy="5.542" rx="5.542" ry="5.542" stroke="none" transform="matrix(1, -0.017, 0.017, 1, 124.998, 81.438)" fill="#FFFFFF00" strokeWidth="0.75" />
        <ellipse className={hoverClass("act2-lyssa-side")} onClick={() => hoverIn("act2-lyssa-side")} onPointerOver={() => hoverIn("act2-lyssa-side")} onPointerEnter={() => hoverIn("act2-lyssa-side")} cx="5.542" cy="5.542" rx="5.042" ry="5.042" transform="matrix(1, -0.017, 0.017, 1, 124.998, 81.438)" fill="#FFFFFF00" stroke="#fff" strokeWidth="0.75" />
        <ellipse className={hoverClass("act2-cap-side")} onClick={() => hoverIn("act2-cap-side")} onPointerOver={() => hoverIn("act2-cap-side")} onPointerEnter={() => hoverIn("act2-cap-side")} transform="translate(134.82 141.15)" stroke="#fff" strokeWidth="0.75" cx="3.342" cy="3.342" rx="2.842" ry="2.842" fill="#FFFFFF00" id="act2-cap-side" />
        <rect className={hoverClass("act2-karin-chaise")} onClick={() => hoverIn("act2-karin-chaise")} onPointerOver={() => hoverIn("act2-karin-chaise")} onPointerEnter={() => hoverIn("act2-karin-chaise")} x="0.5" y="0.5" width="12.366" height="30.931" transform="translate(191.521 74.997)" fill="#FFFFFF00" stroke="#fff" strokeWidth="0.75" />
        <path className={hoverClass("act2-wellington-sofa")} onClick={() => hoverIn("act2-wellington-sofa")} onPointerOver={() => hoverIn("act2-wellington-sofa")} onPointerEnter={() => hoverIn("act2-wellington-sofa")} d="M-11238.487,734.184h-54.346v-9.836a15.565,15.565,0,0,1,3.772-6.23,9.548,9.548,0,0,1,5.739-2.623h33.275a11.3,11.3,0,0,1,8.035,3.361,13.02,13.02,0,0,1,3.523,6.639Z" transform="translate(11357.699 -644.418)" fill="#FFFFFF00" stroke="#fff" strokeWidth="0.75" />
        <path className={hoverClass("act2-hector-armchair")} onClick={() => hoverIn("act2-hector-armchair")} onPointerOver={() => hoverIn("act2-hector-armchair")} onPointerEnter={() => hoverIn("act2-hector-armchair")} d="M-11086.252,820.336a19.229,19.229,0,0,1,1.72-6.557s5.576-.738,7.789,0,5.244,2.623,5.163,6.557-2.132,6.967-5.163,7.213a75.273,75.273,0,0,1-7.789,0S-11086.252,823.205-11086.252,820.336Z" transform="translate(11212.653 -712.996)" fill="#FFFFFF00" stroke="#fff" strokeWidth="0.75" />
        <path className={hoverClass("act2-hector-armchair")} onClick={() => hoverIn("act2-hector-armchair")} onPointerOver={() => hoverIn("act2-hector-armchair")} onPointerEnter={() => hoverIn("act2-hector-armchair")} d="M-11086.252,820.336a19.229,19.229,0,0,1,1.72-6.557s5.576-.738,7.789,0,5.244,2.623,5.163,6.557-2.132,6.967-5.163,7.213a75.273,75.273,0,0,1-7.789,0S-11086.252,823.205-11086.252,820.336Z" transform="translate(11212.653 -686.601)" fill="#FFFFFF00" stroke="#fff" strokeWidth="0.75" />

        <g transform="translate(180 130)" id="visit-home-office/1" onClick={() => clickIn("visit-home-office/1")} onPointerOver={() => hoverIn("visit-home-office/1")} onPointerEnter={() => hoverIn("visit-home-office/1")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-home-office/1")]} title="VISIT"><ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" /></Tooltip>
        </g>

      </g>
    </svg>
  )
})

export { ActMap2 }