import React from 'react'
import { useHistory } from 'react-router'
import { LogoLoadingIcon } from '../../components'
import { isMobile } from '../../utils'

const LoadingPage = ({ className = "", forceLogoOnly, redirect = true, redirectPath = '/welcome' }) => {
  const history = useHistory()
  const redirectEffect = () => { redirect && setTimeout(() => { history.replace(redirectPath) }, 4500) }
  React.useEffect(redirectEffect, [])

  return (
    <div className={`background-dark absolute vh-100 z-index-20 no-touch ${className}`} style={{width: '100%'}}>
      <div className="absolute-center">
        <LogoLoadingIcon logoOnly={forceLogoOnly ?? isMobile()} />
      </div>
    </div>
  )
}

export default React.memo(LoadingPage)
