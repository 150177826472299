import React from 'react'
import { Tooltip } from 'antd'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap4 = React.memo(({ hoverProduct, height, width, setActiveProduct, currentProduct, history = {} }) => {
  const [hovered, setHover] = React.useState("")
  const findProduct = (id) => catalog.find(x => x.id === id)
  const hoverClass = (id) => (hoverProduct && hovered === id ? (`map-product-hover ${id}`) : "")
  const isHovered = (id) => (hovered === id)
  const clickIn = (id) => { setHover(); if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
  const hoverIn = (id) => { if (hovered === id) return; setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 147.243 186.628">
      <g id="Group_521" data-name="Group 521" transform="translate(-52.456 -57.464)">
        <path className="visible-area" data-name="Path 1340" d="M0,0H124.938V68.482H0Z" transform="translate(62.173 172.753)" fill="#2a2a2a" opacity="0.5" />
        <rect id="visible-area" className="visible-area" data-name="Rectangle 972" width="82.387" height="59.792" transform="translate(83.277 97.29)" fill="#2a2a2a" opacity="0.5" />

        <path className="not-interact" d="M0,0H71.892V2.449" transform="translate(126.307 64.255)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M35.071,0H0" transform="translate(59.234 64.078)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M4.9,0H0" transform="translate(54.337 138.566)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V41.91H138.537V37.788" transform="translate(59.661 200.682)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M83.876,0H0" transform="translate(83.322 172.629)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V75.44" transform="translate(59.661 102.02)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V22.541" transform="translate(59.664 57.617)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V7.309" transform="translate(168.989 57.464)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V8.668" transform="translate(198.198 168.295)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
        <rect className="not-interact" transform="translate(187.511 65.938)" stroke="#c4c4c4" strokeWidth="1" x="0.5" y="0.5" width="8.038" height="173.306" fill="#FFFFFF00" />
        <path className="not-interact" d="M0,171.752V0" transform="translate(198.198 66.704)" fill="none" stroke="#fff" strokeWidth="0.75" opacity="0.2" />
        <path className="not-interact" d="M0,0H5.124V42.171H0" transform="translate(52.456 74.98)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
        <path className="not-interact" d="M0,0V22.958" transform="translate(59.63 177.51)" fill="none" stroke="#fff" strokeWidth="0.75" opacity="0.5" />
        <rect id="lampadacriado" transform="translate(84.367 157.787)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="15.686" height="9.429" fill="#FFFFFF00" />
        
        <rect className={hoverClass("act4-hilary-chest")} onClick={() => hoverIn("act4-hilary-chest")} onPointerOver={() => hoverIn("act4-hilary-chest")} onPointerEnter={() => hoverIn("act4-hilary-chest")} transform="translate(135.016 66.233)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="36.891" height="11.167" fill="#FFFFFF00" />
        <rect id="act4-janis-suspension" className={hoverClass("act4-janis-suspension")} onClick={() => hoverIn("act4-janis-suspension")} onPointerOver={() => hoverIn("act4-janis-suspension")} onPointerEnter={() => hoverIn("act4-janis-suspension")} transform="translate(103.09 165.436)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="41.063" height="1.781" fill="#FFFFFF00" />

        <circle id="act4-sebastian-pouf" className={hoverClass("act4-sebastian-pouf")} onClick={() => hoverIn("act4-sebastian-pouf")} onPointerOver={() => hoverIn("act4-sebastian-pouf")} onPointerEnter={() => hoverIn("act4-sebastian-pouf")} transform="translate(83.325 83.372)" stroke="#fff" strokeWidth="0.75" cx="12.688" cy="12.688" r="12.188" fill="#FFFFFF00" />
        <rect id="act4-anny-bed" className={hoverClass("act4-anny-bed")} onClick={() => hoverIn("act4-anny-bed")} onPointerOver={() => hoverIn("act4-anny-bed")} onPointerEnter={() => hoverIn("act4-anny-bed")} transform="translate(104.48 121.977)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="38.282" height="42.801" fill="#FFFFFF00" />
        <rect id="act4-lady-bedside" className={hoverClass("act4-lady-bedside")} onClick={() => hoverIn("act4-lady-bedside")} onPointerOver={() => hoverIn("act4-lady-bedside")} onPointerEnter={() => hoverIn("act4-lady-bedside")} transform="translate(147.188 157.787)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="15.686" height="9.429" fill="#FFFFFF00" />
        <ellipse id="act4-sharon-table-lamp" className={hoverClass("act4-sharon-table-lamp")} onClick={() => hoverIn("act4-sharon-table-lamp")} onPointerOver={() => hoverIn("act4-sharon-table-lamp")} onPointerEnter={() => hoverIn("act4-sharon-table-lamp")} transform="translate(90.621 160.22)" stroke="#fff" strokeWidth="0.75" cx="2.26" cy="2.26" rx="1.76" ry="1.76" fill="#FFFFFF00" />
        <ellipse id="act4-smith-table" className={hoverClass("act4-smith-table")} onClick={() => hoverIn("act4-smith-table")} onPointerOver={() => hoverIn("act4-smith-table")} onPointerEnter={() => hoverIn("act4-smith-table")} transform="translate(153.45 160.22)" stroke="#fff" strokeWidth="0.75" cx="2.26" cy="2.26" rx="1.76" ry="1.76" fill="#FFFFFF00" />
        
        <ellipse className={hoverClass("act4-rosie-dressing" )} onClick={() => hoverIn("act4-rosie-dressing" )} onPointerOver={() => hoverIn("act4-rosie-dressing" )} onPointerEnter={() => hoverIn("act4-rosie-dressing" )} transform="translate(139.217 207.168)" stroke="#fff" strokeWidth="0.75" cx="2.26" cy="2.26" rx="1.76" ry="1.76" fill="#FFFFFF00" />
        <path className={hoverClass("act4-rosie-dressing" )} onClick={() => hoverIn("act4-rosie-dressing" )} onPointerOver={() => hoverIn("act4-rosie-dressing" )} onPointerEnter={() => hoverIn("act4-rosie-dressing" )} d="M0,0V8.3" transform="translate(141.479 205.53)" fill="none" stroke="#fff" strokeWidth="0.75" />
        <rect id="act4-rosie-dressing" className={hoverClass("act4-rosie-dressing")} onClick={() => hoverIn("act4-rosie-dressing")} onPointerOver={() => hoverIn("act4-rosie-dressing")} onPointerEnter={() => hoverIn("act4-rosie-dressing")} transform="translate(132.616 194.305)" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="11.167" height="28.896" fill="#FFFFFF00" />
        <ellipse id="act4-rene-pouf" className={hoverClass("act4-rene-pouf")} onClick={() => hoverIn("act4-rene-pouf")} onPointerOver={() => hoverIn("act4-rene-pouf")} onPointerEnter={() => hoverIn("act4-rene-pouf")} transform="translate(120.816 204.734)" stroke="#fff" strokeWidth="0.75" cx="5.214" cy="5.214" rx="4.714" ry="4.714" fill="#FFFFFF00" />
      
        <g transform="translate(120, 80)" id="visit-bedroom/2" onClick={() => clickIn("visit-bedroom/2")} onPointerOver={() => hoverIn("visit-bedroom/2")} onPointerEnter={() => hoverIn("visit-bedroom/2")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-bedroom/2")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <g transform="translate(80, 110)" id="visit-bedroom/1" onClick={() => clickIn("visit-bedroom/1")} onPointerOver={() => hoverIn("visit-bedroom/1")} onPointerEnter={() => hoverIn("visit-bedroom/1")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-bedroom/1")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <g transform="translate(75, 140)" id="visit-bedroom/3" onClick={() => clickIn("visit-bedroom/3")} onPointerOver={() => hoverIn("visit-bedroom/3")} onPointerEnter={() => hoverIn("visit-bedroom/3")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-bedroom/3")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>


        <g transform="translate(68, 180)" id="visit-bedroom/4" onClick={() => clickIn("visit-bedroom/4")} onPointerOver={() => hoverIn("visit-bedroom/4")} onPointerEnter={() => hoverIn("visit-bedroom/4")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-bedroom/4")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>

        <g transform="translate(120, 180)" id="visit-bedroom/5" onClick={() => clickIn("visit-bedroom/5")} onPointerOver={() => hoverIn("visit-bedroom/5")} onPointerEnter={() => hoverIn("visit-bedroom/5")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-bedroom/5")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>


      </g>
    </svg>
  )
})

export { ActMap4 }