import React, { useRef } from 'react'
import { Row, Col, Tooltip } from 'antd';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router';
import { updateCurrentAct } from './../../effects/actions'
import { RenderMap, isMobile } from '../../utils';
import MapButtonExpand from '../../assets/button/map-expand.png'

const acts = require('./../../assets/mock-data/act.json')

const TabsPanelComponent = React.memo(({ panoramaAct, currentAct, progress, updateCurrentAct, onSetExpand, children, initialCompass }) => {
    const history = useHistory()
    const activeAct = (item) => currentAct.act === item.act ? `active` : ``
    const [currentProduct, setActiveProduct] = React.useState();

    const ref = useRef(null);
    const refMapaMenu = useRef(null);
    const refTitle = useRef(null);
    const expandedStyle = { cursor: 'pointer', left: 'unset', width: 30, height: 30, fontSize: 30, transform: `rotate(180deg)`, right: 10, zIndex: 20, top: 12 }

    // height: heighSpace - 48, display: 'flex', flexDirection: 'column', paddingTop: 20

    return (
        <Space ref={ref}>
            <Row className="h-100">
                <div onClick={() => onSetExpand(false)} className="absolute" style={expandedStyle}><img src={MapButtonExpand} alt="expand" /></div>

                <Col md={14} sm={24} className="pr-3 tabs-panel-50" ref={refMapaMenu}>
                    <Row className="map-row">
                        <ColPadding span={24} className="containerImage">
                            <MapZone initialCompass={initialCompass}>
                                <RenderMap hoverProduct={true} map={currentAct.act} height="100%" width="100%" currentProduct={currentProduct} setActiveProduct={setActiveProduct} history={history} />
                                {panoramaAct === currentAct?.act && children}
                            </MapZone>
                        </ColPadding>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <nav role='navigation' className="main-nav" id="main-nav">
                                <ul id="main-nav-list">
                                    {acts.map((item, key) => (
                                        <li onClick={() => updateCurrentAct(item)} key={key} className={activeAct(item)}><Tooltip title={item.title}><span>{item.tab}</span></Tooltip></li>
                                    ))}
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Col>

                {!currentProduct && (
                    <Col md={10} sm={24} className="tabs-panel-50 m-overflow-auto mh-100">
                        <Row className="pt-2"><Col span={24} className="text din size-14 white current-act">{currentAct.tab}</Col></Row>
                        <Row ref={refTitle}><Col span={24} className="text didot size-32 white mb-2 title-act">{currentAct.title}</Col></Row>
                        <Row className="overflow-auto pr-1 h-100"><Col span={24} className="text din size-14 text-grey h-100 overflow-scroll"><div dangerouslySetInnerHTML={{ __html: currentAct.description }} /></Col></Row>
                        {!isMobile() && <Row align="bottom ml-1"><ProgressBar progress={progress}><p>{progress}%</p></ProgressBar></Row>}
                    </Col>)
                }
                {currentProduct && (
                    <Col md={10} sm={24} className="tabs-panel-50 m-overflow-auto mh-100">
                        <Row className="overflow-auto pr-1 h-100">
                            <Col span={24} className="text din size-14 white px-4 h-100 overflow-scroll product-container-map">
                                <Row><Col span={24} className="text din size-14 white current-product">{currentProduct.product}</Col></Row>
                                <Row ref={refTitle}><Col span={24} className="text didot size-32 white mb-2 current-brand">{currentProduct.brand}</Col></Row>
                                <div className="w-100 text-center mh-200px"><img className="mh-200px" src={`/products/${currentProduct.id}_2.png`} alt="product" /></div>
                                <br />

                                <hr className="mt-05" />

                                <div className="description-text" dangerouslySetInnerHTML={{ __html: currentProduct.description }} />
                                <br />
                                <div className="text-grey" dangerouslySetInnerHTML={{ __html: currentProduct.material }} />
                                <br />
                                <hr className="text white hr-white" />
                                <span className="text din mb-3 text-grey">{currentProduct.dimensions} | {currentProduct.dimensionslabel}</span>
                                <br />
                                <br />
                            </Col>
                            {!isMobile() && <Row align="bottom ml-1"><ProgressBar progress={progress}><p>{progress}%</p></ProgressBar></Row>}
                        </Row>
                    </Col>)
                }
                {isMobile() && <Row align="bottom ml-1 w-100"><ProgressBar progress={progress}><p>{progress}%</p></ProgressBar></Row>}
            </Row>
        </Space>
    )
})
const mapStateToProps = ({ app, visitorAPI }) => {
    const { currentAct } = app
    const { progress } = visitorAPI
    return { currentAct, progress }
}
export default connect(mapStateToProps, { updateCurrentAct })(TabsPanelComponent);

export const MapZone = styled.div`
    padding: 64px; 
    z-index:2; 
    top: 0; 
    bottom: 0; 
    right: 0; 
    left: 0; 
    width: auto; 
    height: 100%;
    margin: 0 auto;
    
    @media (max-width: 1200px) {
        height: 100%;
        padding: 32px !important;
    }
    @media (max-width: 768px) {
        height: 100%;
        padding: 4px !important;
    }
    .compass-container{
        position: absolute;
        @media  (max-width: 768px){
            img{
                top: calc(50% + ${p =>  (2.00 * p.initialCompass[1] - 32) ?? -32}px);
                left: calc(50% + ${p => (2.00 * p.initialCompass[0] - 32) ?? -32}px);
            }
        }
        @media  (max-height: 667px) and (max-width: 768px){
            img{
                top: calc(50% + ${p =>  (1.40 * p.initialCompass[1] - 32) ?? -32}px);
                left: calc(50% + ${p => (1.50 * p.initialCompass[0] - 32) ?? -32}px);
            }
        }
        @media  (min-width: 768px){
            img{
                 top: calc(50% + ${p => (1.25 * p.initialCompass[1] - 32) ?? -32}px);
                left: calc(50% + ${p => (2.25 * p.initialCompass[0] - 32) ?? -32}px);
            }   
        }
    }
`
export const Title = styled.h2`color:white;`
export const Space = styled.div`
    position: absolute;
    padding: 20px; 
    background-color:rgba(0, 0, 0, 0.9);

    .tabs-panel-50{ 
        @media (max-width: 767px) { 
        height: calc(50% - 8px);
        &.m-overflow-auto{overflow:auto}
    }}
    .map-row{ @media (max-width: 767px) { height: calc(100% - 50px) }}
    .map-row{ @media (min-width: 767px) { height: calc(100% - 36px) }}

    .text-grey{
        color: #C4C4C4;
    }

    .current-product{
        text-transform: capitalize; 
    }
    .description-text{
        color: #C4C4C4;
    }
    .1tabs-panel-column-bottom{height: -12px}
    .1tabs-panel-column{
        height: calc(100% - 45px);
        margin-top: 20px;

        @media (max-width: 767px) {
            margin-top: 0px;
            overflow: hidden;
            1height: calc(50% - 15px);
            1min-height: calc(50% - 15px);
        }
    }

    @media (max-width: 767px) {
        height: calc(100vh - 24px); 
        overflow: hidden;

        .title-act{
            text-align: center;
        }
        .current-act{
            display: none;
        }
    }

    @media (min-width: 768px) { 
        height: calc(100vh - 32px); 
        max-height: 450px; 
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    @media (min-width: 1400px) { 
        max-height: 600px;
    }
`
export const ProgressBar = styled.div`
    position: relative;
    margin-top: 10px;
    margin-left: 5px;
    width: 100%;
    border-bottom: solid 1px #fff;
    height: 1px;

    @media (min-width: 768px) {
            position: absolute;
            bottom: 14px;
            left: 0;
        }
    }

    p {
        position: absolute;
        top: 4px;
        width: 40px;
        text-align: center;
        transform: translateX(-50%);
        left: ${p => `${p.progress}%`};
        transition: all .2s ease;
    }

    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        width: ${p => `${p.progress}%`};
        height: 1px;
        background: #703540;
        transition: all .2s ease;
    }
`
export const ColPadding = styled(Col)`
    padding: 0 24px; 
    &.containerImage {
        height: 100%;
        @media (max-width: 768px) {
            height: 100%;
            padding: 18px;
        }
        @media (min-width: 769px) {
            height: 330px;
        }
        @media (min-width: 1400px) {
            height: 465px;
        }
    }
`