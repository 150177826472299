import React from 'react';
import PropTypes from 'prop-types';
const borderStyle = { border: '4px solid transparent' }
const MenuPause = React.memo(({ fill }) => (
    <svg style={borderStyle} fill={fill} xmlns="http://www.w3.org/2000/svg"  width={22} height={22} x="0px" y="0px" viewBox="0 0 477.867 477.867">
        <path d="M187.733,0H51.2c-9.426,0-17.067,7.641-17.067,17.067V460.8c0,9.426,7.641,17.067,17.067,17.067h136.533 c9.426,0,17.067-7.641,17.067-17.067V17.067C204.8,7.641,197.159,0,187.733,0z" />
        <path d="M426.667,0H290.133c-9.426,0-17.067,7.641-17.067,17.067V460.8c0,9.426,7.641,17.067,17.067,17.067h136.533 c9.426,0,17.067-7.641,17.067-17.067V17.067C443.733,7.641,436.092,0,426.667,0z" />
    </svg>

));
MenuPause.propTypes = { fill: PropTypes.string };
MenuPause.defaultProps = { fill: "#fff" };
export { MenuPause }