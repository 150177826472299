import { combineReducers } from 'redux'
import visitorReducer from './api/visitor'
import uiReducer from './ui'
import favoriteReducer from './favorites'
import appReducer from './app'

export const rootReducers = combineReducers({
  visitorAPI: visitorReducer,
  ui: uiReducer,
  favorites: favoriteReducer,
  app: appReducer
})
