/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { StyledDiv, StyledP, StyledPIn, StyledImg } from './show-player.styled'

const ShowPlayerComponent = ({ show, currentActSong }) => {
  if(currentActSong) {
    return (
      <StyledDiv show={show}>
          <StyledImg src={require(`./../../assets/cover/${currentActSong.image}`)} />
          
          <div>
            <StyledP>Singer Name</StyledP>
            <StyledPIn>{currentActSong.title}</StyledPIn>
          </div>
          
      </StyledDiv>
    );
  } else {
    return (
      <StyledDiv show={show}>
        <StyledImg src={require(`./../../assets/cover/default.png`)} />
        <div>
          <StyledPIn reset>unknown</StyledPIn>
          <StyledP reset>unknown</StyledP>
        </div>
      </StyledDiv>
    );
  }
};

const mapStateToProps = ({ app }) => {
  const { currentActSong } = app
  return { currentActSong }
}

export default connect(mapStateToProps, null)(React.memo(ShowPlayerComponent));
