import React from 'react'
import { Tooltip } from 'antd'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap3 = React.memo(({ hoverProduct, height, width, setActiveProduct, currentProduct, history = {} }) => {
  const [hovered, setHover] = React.useState("")
  const findProduct = (id) => catalog.find(x => x.id === id)
  const hoverClass = (id) => (hoverProduct && hovered === id ? (`map-product-hover ${id}`) : "")
  const isHovered = (id) => (hovered === id)
  const clickIn = (id) => { setHover(); if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
  const hoverIn = (id) => { if (hovered === id) return; setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 151.409 170.401">
      <g id="Group_517" data-name="Group 517" transform="translate(-49.089 -40.431)">
        <rect id="visible-area" className="visible-area" width="70.517" height="99.009" transform="translate(99.107 80.104)" fill="#2a2a2a" opacity="0.5" />
        <path className="not-interact" d="M3.728,0H0V42.565H3.728" transform="translate(194.441 103.663)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path className="not-interact" d="M0,0V39.031" transform="translate(50.589 52.27)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" opacity="0.2" />
        <path className="not-interact" d="M0,63.677V0" transform="translate(102.245 199.186) rotate(-90)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" opacity="0.2" />
        <path className="not-interact" d="M.675,63.689,0,0" transform="translate(191.93 135.997)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M.675,62.308,0,0" transform="translate(191.93 50.238)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M34.614,0H0" transform="translate(165.884 199.209)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V10.289H48.585" transform="translate(50.589 40.431)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V85.636" transform="translate(76.621 82.528)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M26.032,36.56H0V0" transform="translate(50.589 91.304)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V71.776H51.558" transform="translate(50.589 127.433)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M0,0V11.483" transform="translate(50.59 199.349)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />
        <path className="not-interact" d="M31.356,0H0" transform="translate(166.026 50.328)" fill="#ffffff00" stroke="#7a303f" strokeWidth="2.01" />


        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.361,10.361,0,0,1,5.809.3,3.544,3.544,0,0,1,9.177.585c1.319.967,4.189,4,4.189,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.555,8.454A2.978,2.978,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="translate(126.769 85.689)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.382,10.382,0,0,1,5.809.3,3.543,3.543,0,0,1,9.176.585c1.32.967,4.19,4,4.19,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.554,8.454A2.976,2.976,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="matrix(0.848, -0.53, 0.53, 0.848, 106.305, 114.721)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.382,10.382,0,0,1,5.809.3,3.543,3.543,0,0,1,9.176.585c1.32.967,4.19,4,4.19,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.554,8.454A2.976,2.976,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="matrix(0.602, -0.799, 0.799, 0.602, 106.215, 141.546)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.382,10.382,0,0,1,5.809.3,3.543,3.543,0,0,1,9.176.585c1.32.967,4.19,4,4.19,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.554,8.454A2.976,2.976,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="matrix(-0.242, -0.97, 0.97, -0.242, 129.863, 175.845)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.382,10.382,0,0,1,5.809.3,3.543,3.543,0,0,1,9.176.585c1.32.967,4.19,4,4.19,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.554,8.454A2.976,2.976,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="translate(166.094 141.741) rotate(133)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <path id="act3-ambrose-chair" className={hoverClass("act3-ambrose-chair")} onClick={() => hoverIn("act3-ambrose-chair")} onPointerOver={() => hoverIn("act3-ambrose-chair")} onPointerEnter={() => hoverIn("act3-ambrose-chair")} d="M2.842,2.339A10.382,10.382,0,0,1,5.809.3,3.543,3.543,0,0,1,9.176.585c1.32.967,4.19,4,4.19,5.262S12.018,9.2,10.688,10.5s-4.139,2.871-5.7,2.286S1.139,9.039.554,8.454A2.976,2.976,0,0,1,.21,5.52C.586,4.292,1.442,3.644,2.842,2.339Z" transform="translate(163.389 126.422) rotate(-179)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" />
        <ellipse id="act3-jazz-suspension" className={hoverClass("act3-jazz-suspension")} onClick={() => hoverIn("act3-jazz-suspension")} onPointerOver={() => hoverIn("act3-jazz-suspension")} onPointerEnter={() => hoverIn("act3-isaac-pouf")} transform="translate(58.634 184.617)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" cx="4.274" cy="4.274" rx="3.774" ry="3.774" />
        <ellipse id="act3-isaac-pouf" className={hoverClass("act3-isaac-pouf")} onClick={() => hoverIn("act3-isaac-pouf")} onPointerOver={() => hoverIn("act3-isaac-pouf")} onPointerEnter={() => hoverIn("act3-jazz-suspension")} transform="translate(81.656 87.337)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" cx="4.986" cy="4.986" rx="4.486" ry="4.486" />
        <rect id="act3-rick-sideboard" className={hoverClass("act3-rick-sideboard")} onClick={() => hoverIn("act3-rick-sideboard")} onPointerOver={() => hoverIn("act3-rick-sideboard")} onPointerEnter={() => hoverIn("act3-rick-sideboard")} transform="translate(78.467 102.909)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="10.753" height="50.285" />
        <rect id="act3-eda-dining" className={hoverClass("act3-eda-dining")} onClick={() => hoverIn("act3-eda-dining")} onPointerOver={() => hoverIn("act3-eda-dining")} onPointerEnter={() => hoverIn("act3-eda-dining")} transform="translate(122.639 102.192)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="24.642" height="54.203" />
        <rect id="act3-lewis-cupboard" className={hoverClass("act3-lewis-cupboard")} onClick={() => hoverIn("act3-lewis-cupboard")} onPointerOver={() => hoverIn("act3-lewis-cupboard")} onPointerEnter={() => hoverIn("act3-lewis-cupboard")} transform="translate(177.151 154.068)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="12.89" height="31.053" />
        <rect id="act3-lewis-cupboard" className={hoverClass("act3-lewis-cupboard")} onClick={() => hoverIn("act3-lewis-cupboard")} onPointerOver={() => hoverIn("act3-lewis-cupboard")} onPointerEnter={() => hoverIn("act3-lewis-cupboard")} transform="translate(177.151 68.144)" fill="#ffffff00" stroke="#fff" strokeWidth="0.75" x="0.5" y="0.5" width="12.89" height="31.053" />


        <g transform="translate(100, 80)" id="visit-dining-room/1" onClick={() => clickIn("visit-dining-room/1")} onPointerOver={() => hoverIn("visit-dining-room/1")} onPointerEnter={() => hoverIn("visit-dining-room/1")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-dining-room/1")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>


        <g transform="translate(130, 65)" id="visit-dining-room/2" onClick={() => clickIn("visit-dining-room/2")} onPointerOver={() => hoverIn("visit-dining-room/2")} onPointerEnter={() => hoverIn("visit-dining-room/2")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-dining-room/2")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>


        <g transform="translate(160, 80)" id="visit-dining-room/3" onClick={() => clickIn("visit-dining-room/3")} onPointerOver={() => hoverIn("visit-dining-room/3")} onPointerEnter={() => hoverIn("visit-dining-room/3")} fill="#fff">
          <Tooltip trigger={["hover", "click", isHovered("visit-dining-room/3")]} title="VISIT">
            <ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" />
          </Tooltip>
        </g>


      </g>
    </svg>
  )
})

export { ActMap3 }