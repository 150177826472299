import React from 'react';
import PropTypes from 'prop-types';

const MenuShare = React.memo(({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="Group_412" data-name="Group 412" transform="translate(-1816 -230)">
            <path id="Path_223" data-name="Path 223" d="M1825.8,433.436l2.238,5.513L1834,425.028Zm7.529-9.487L1819,430.423l5.969,2.1Z" transform="translate(6.5 -184.449)" fill={fill} />
        </g>
    </svg>
));
MenuShare.propTypes = { fill: PropTypes.string };
MenuShare.defaultProps = { fill: "#fff" };
export { MenuShare };
