import { FavoritesActionType } from '../../types'

export const addItem = item => {
  return {
    type: FavoritesActionType.ADD,
    payload: item
  }
}

export const removeItem = item => {
  return {
    type: FavoritesActionType.REMOVE,
    payload: item
  }
}

export const syncWishlist = () => {
  return {
    type: FavoritesActionType.SYNC_LIST
  }
}

export const wishlistUpdateFailure = response => {
  return {
    type: FavoritesActionType.SYNC_FAILURE,
    payload: response
  }
}

export const wishlistUpdateSuccess = response => {
  return {
    type: FavoritesActionType.SYNC_SUCCESS,
    payload: response
  }
}
