/* eslint-disable no-unused-expressions */
import React from 'react'
import { Tooltip } from 'antd'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap1 = React.memo(({ hoverProduct, height, width, setActiveProduct, currentProduct, history = {} }) => {
    const [hovered, setHover] = React.useState("")
    const findProduct = (id) => catalog.find(x => x.id === id)
    const hoverClass = (id) => (hoverProduct && hovered === id ? (`map-product-hover ${id}`) : "")
    const isHovered = (id) => (hovered === id)
    const clickIn = (id) => { setHover(); if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    const hoverIn = (id) => { if (hovered === id) return; setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 182.385 116.341">
            <rect id="visible-area" className="visible-area" width="83.695" height="58.764" transform="translate(72.125 38.979)" fill="#2a2a2a" opacity="0.5" />
            <rect className="not-interact" x="0.5" y="0.5" width="166.545" height="93.279" fill="none" transform="translate(7.137 10.611)" stroke="rgba(255,255,255,0.25)" strokeWidth="1" />
            <path className="not-interact" d="M-16138.5,6342.645v-7.6h54.289v7.6" transform="translate(16159.484 -6226.304)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
            <path className="not-interact" d="M-16138.5,6342.645v-7.6h56.054v7.6" transform="translate(16224.31 -6226.304)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
            <path className="not-interact" d="M-10641.361,1089.425v-8.643l-45.5.029" transform="translate(10822.246 -975.017)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
            <path className="not-interact" d="M-10798.8,1080.93h-43.976" transform="translate(10908.099 -974.617)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
            <path className="not-interact" d="M-10806.984,1081.9h-39.138" transform="translate(10846.122 -975.603)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
            <path className="not-interact" d="M-10750.275,869.3h-60.395v-9.876" transform="translate(10873.331 -859.424)" fill="none" stroke="#7a303f" strokeWidth="2.01" />
            <path className="not-interact" d="M-10576.653,879.626h4.483" transform="translate(10751.145 -869.97)" fill="none" stroke="#7a303f" strokeWidth="2.01" />

            <rect transform="translate(68.059 12.465)" stroke="#fff" strokeWidth="0.75" className={hoverClass("act1-jill-console")} onPointerOver={() => hoverIn("act1-jill-console")} onPointerEnter={() => hoverIn("act1-jill-console")} x="0.5" y="0.5" width="48.861" height="12.059" fill="#FFFFFF00" />
            <ellipse transform="translate(93.557 26.501)" stroke="#fff" strokeWidth="0.75" className={hoverClass("act1-rene-pouf")} onPointerOver={() => hoverIn("act1-rene-pouf")} onPointerEnter={() => hoverIn("act1-rene-pouf")} cx="8.607" cy="8.607" rx="8.107" ry="8.107" fill="#FFFFFF00" />
            <ellipse transform="translate(76.399 14.84)" stroke="#fff" strokeWidth="0.75" className={hoverClass("act1-sharon-table")} onPointerOver={() => hoverIn("act1-sharon-table")} onPointerEnter={() => hoverIn("act1-sharon-table")}cx="5.082" cy="5.045" rx="4.582" ry="4.545" fill="#FFFFFF00" />
            <rect transform="translate(78.671 11.132)" stroke="#fff" strokeWidth="0.75" className={hoverClass("act1-rod-mirror")} onPointerOver={() => hoverIn("act1-rod-mirror")} onPointerEnter={() => hoverIn("act1-rod-mirror")} x="0.5" y="0.5" width="28.085" height="0.187" fill="#FFFFFF00" />

            <g id="visit-hall/1" onClick={() => clickIn("visit-hall/1")} onPointerOver={() => hoverIn("visit-hall/1")} onPointerEnter={() => hoverIn("visit-hall/1")} transform="translate(136.399 74.84)" fill="#fff">
                <Tooltip trigger={["hover", "click", isHovered("visit-hall/1")]} title="VISIT"><ellipse cx="5.082" cy="5.045" rx="4.582" ry="4.545" /></Tooltip>
            </g>
        </svg >
    )
})

export { ActMap1 }

//           visit-bedroom/1
//           visit-bedroom/2
//           visit-bedroom/3
//           visit-bedroom/4
//           visit-bedroom/5
//           visit-catalog/1
//           visit-closet/1
//           visit-closet/3
//           visit-dining-room/1
//           visit-dining-room/2
//           visit-dining-room/3
//           visit-hall/1
//           visit-hall/2
//           visit-hall/1
//           visit-hall/2
//           visit-hall/3
//           visit-hall/4
//           visit-living-room/1
//           visit-living-room/2
//           visit-living-room/3

//           /product/act1-rene-pouf
//           /product/act1-rod-mirror
//           /product/act1-sharon-table
//           /product/act2-cap-side
//           /product/act2-hector-armchair
//           /product/act2-wellington-sofa
//           /product/act3-ambrose-chair
//           /product/act3-eda-dining
//           /product/act3-rick-sideboard
//           /product/act4-anny-bed
//           /product/act4-hilary-chest
//           /product/act4-janis-suspension
//           /product/act4-lady-bedside
//           /product/act4-rene-pouf
//           /product/act4-rosie-dressing
//           /product/act4-sebastian-pouf
//           /product/act4-sharon-table-lamp
//           /product/act4-smith-table
//           /product/act5-cosy-armchair
//           /product/act5-dara-desk
//           /product/act5-martin-table
//           /product/act5-peterson-tv
//           /product/act5-praga-bookshelf
//           /product/act5-randolph-floor
//           /product/act5-robson-chair