/* eslint-disable no-unused-expressions */
import { Tooltip } from 'antd'
import React from 'react'
// const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMapFull = React.memo(({ height, width, history = {} }) => {
    const [hovered, setHover] = React.useState("catalog")
    const opacityClass = (id) => (hovered?.includes(id) ? 1 : 0.25)
    const isHovered = (id) => (hovered === id)
    const clickIn = (id) => { if (history && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    const hoverIn = (id) => { if (hovered === id) return; setHover(id) }

    return (
        <svg width={width} height={height} viewBox="0 0 559 574" fill="none" xmlns="http://www.w3.org/2000/svg">

            <Tooltip visible={hovered?.includes('catalog')} title="Main Hall">
                <g opacity={opacityClass("catalog")} onClick={() => hoverIn("catalog")} onPointerOver={() => hoverIn("catalog")} onPointerEnter={() => hoverIn("catalog")} id="catalog">
                    <rect onClick={() => hoverIn("catalog")} onPointerOver={() => hoverIn("catalog")} onPointerEnter={() => hoverIn("catalog")} id="catalog" x="185" y="242" width="190" height="275" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector" d="M375.015 287.549V242.602H328.807" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_2" d="M375.014 435.625H299.221" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_3" d="M185.104 435.625L184.234 352.603" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_4" d="M185.104 322.983L184.234 242.705" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_5" d="M337.238 435.626V443.926" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_6" d="M220.621 435.626V443.486" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_7" d="M302.349 243.139H257.578" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_8" d="M261.471 435.625H178.984" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_9" d="M337.236 435.626V520.226" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_10" d="M220.621 435.626V519.785" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_11" d="M185.156 436.265L184.283 353.912" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_12" d="M185.156 323.588L184.283 243.02" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_13" d="M374.998 287.31V242.61H362.49" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_14" d="M375.027 251.379V242.599L328.807 242.629" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_15" d="M302.314 243.157H257.645" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_16" d="M231.044 243.14H191.287" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_17" d="M184.23 252.259V243.209" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_18" d="M273.893 243.14H257.678" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_19" d="M374.923 435.632H368.516" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_20" d="M375 388L375 436" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_21" d="M372.363 352.821H343.789V407.49H372.363V352.821Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_22" d="M341.178 323.565H311.734V378.234H341.178V323.565Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_23" d="M372.363 281.298H343.789V335.967H372.363V281.298Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_24" d="M216.353 312.352H187.779V367.021H216.353V312.352Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_25" d="M248.394 328.758H219.82V379.947H248.394V328.758Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_26" d="M298.733 277.028H244.064V306.472H298.733V277.028Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_27" d="M298.706 371.19H257.955V397.154H298.706V371.19Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_28" d="M307.378 400.497H266.627V426.461H307.378V400.497Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_29" d="M309.137 310.615H251.859V367.893H309.137V310.615Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_30" d="M335.137 245.944H278.729V274.518H335.137V245.944Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_31" d="M267.505 245.944H212.836V274.518H267.505V245.944Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_32" d="M289.134 330.617H270.998V348.753H289.134V330.617Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="book" d="M282.867 335.005L276.561 336.23L278.224 344.788L284.53 343.563L282.867 335.005Z" fill="#7A303F" />
                    <path className="no-touch" id="Path 432" d="M192.351 312.101H187.531V367.14H192.351" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 436" d="M364.389 281.033H372.673V336.206H364.389" stroke="#C4C4C4" />
                    <path className="no-touch" id="Path 446" d="M212.605 253.344V245.624H267.75V253.344" stroke="#C4C4C4" />
                    <path className="no-touch" id="Path 447" d="M278.455 253.344V245.624H335.392V253.344" stroke="#C4C4C4" />
                    <path className="no-touch" id="Path 437" d="M212.568 252.377V245.663H264.658" stroke="#C4C4C4" />
                    <path className="no-touch" id="Path 438" d="M366.059 352.513H372.759V407.639H366.059" stroke="#C4C4C4" />
                </g>
            </Tooltip>

            <Tooltip visible={hovered?.includes('home-office')} title="Home Office">
                <g opacity={opacityClass("home-office")} onClick={() => hoverIn("home-office")} onPointerOver={() => hoverIn("home-office")} onPointerEnter={() => hoverIn("home-office")} id="home-office">
                    <rect onClick={() => hoverIn("home-office")} onPointerOver={() => hoverIn("home-office")} onPointerEnter={() => hoverIn("home-office")} id="home-office" x="369" y="145" width="153" height="194" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector_48" d="M368.469 144.98H518.369V149.361" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_49" d="M518.367 257.114V197.014" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_50" d="M518.387 318.641V338.785" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_51" d="M518.391 329.617V339.172" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_52" d="M455.703 234.971H433.514V285.201H455.703V234.971Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_53" d="M516.286 197.87H500.082V248.1H516.286V197.87Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 401" d="M480.944 173.383C480.472 175.023 480.787 176.742 485.036 180.022C489.285 183.302 492.355 185.802 496.053 185.255C499.751 184.708 500.302 184.943 504.079 180.022C507.856 175.101 508.879 165.26 504.079 161.589C499.279 157.918 488.738 159.324 485.044 164.557C483.916 166.148 483.031 167.335 482.284 168.408C480.839 170.486 480.455 171.48 480.944 173.383Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_54" d="M412.168 260.349C411.516 261.099 411.197 262.055 411.267 263.042C411.338 264.031 411.793 264.934 412.547 265.585L418.627 270.831C420.193 272.182 422.573 272.017 423.934 270.463L429.219 264.429L429.229 264.418C429.88 263.668 430.2 262.712 430.129 261.725C430.058 260.736 429.603 259.833 428.849 259.182L422.769 253.936C421.203 252.586 418.823 252.75 417.462 254.304L412.177 260.338L412.168 260.349ZM411.413 259.693C411.417 259.689 411.421 259.684 411.425 259.68L416.71 253.645C418.434 251.677 421.439 251.468 423.422 253.179L429.502 258.425C431.481 260.132 431.694 263.106 429.983 265.074C429.979 265.079 429.975 265.083 429.971 265.087L424.686 271.122C422.962 273.091 419.957 273.299 417.974 271.588L411.894 266.342C409.916 264.635 409.702 261.662 411.413 259.693Z" fill="white" />
                    <path className="no-touch" id="Vector_55" d="M516.286 250.526H504.57V335.651H516.286V250.526Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_56" d="M466.922 147.415H405.836V159.779H466.922V147.415Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 399" opacity="0.2" d="M518.367 149.36V318.64" stroke="white" strokeWidth="2" />
                    <g onClick={() => clickIn("visit-living-room/1")} onPointerOver={() => hoverIn("visit-home-office/1")} onPointerEnter={() => hoverIn("visit-home-office/1")}><Tooltip trigger={["hover", "click", isHovered("visit-home-office/1")]} title="VISIT"><path id="visit-4" d="M458 185C461.866 185 465 181.866 465 178C465 174.134 461.866 171 458 171C454.134 171 451 174.134 451 178C451 181.866 454.134 185 458 185Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/2")} onPointerOver={() => hoverIn("visit-home-office/2")} onPointerEnter={() => hoverIn("visit-home-office/2")}><Tooltip trigger={["hover", "click", isHovered("visit-home-office/2")]} title="VISIT"><path id="visit-5" d="M487 202C490.866 202 494 198.866 494 195C494 191.134 490.866 188 487 188C483.134 188 480 191.134 480 195C480 198.866 483.134 202 487 202Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/3")} onPointerOver={() => hoverIn("visit-home-office/3")} onPointerEnter={() => hoverIn("visit-home-office/3")}><Tooltip trigger={["hover", "click", isHovered("visit-home-office/3")]} title="VISIT"><path id="visit-6" d="M402 248C405.866 248 409 244.866 409 241C409 237.134 405.866 234 402 234C398.134 234 395 237.134 395 241C395 244.866 398.134 248 402 248Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/4")} onPointerOver={() => hoverIn("visit-home-office/4")} onPointerEnter={() => hoverIn("visit-home-office/4")}><Tooltip trigger={["hover", "click", isHovered("visit-home-office/4")]} title="VISIT"><path id="visit-7" d="M427 319C430.866 319 434 315.866 434 312C434 308.134 430.866 305 427 305C423.134 305 420 308.134 420 312C420 315.866 423.134 319 427 319Z" fill="white" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered?.includes('bedroom')} title="Bedroom">
                <g opacity={opacityClass("bedroom")} onClick={() => hoverIn("bedroom")} onPointerOver={() => hoverIn("bedroom")} onPointerEnter={() => hoverIn("bedroom")} id="bedroom">
                    <rect onClick={() => hoverIn("bedroom")} onPointerOver={() => hoverIn("bedroom")} onPointerEnter={() => hoverIn("bedroom")} id="bedroom" x="375" y="339" width="184" height="235" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector_57" d="M381.022 338.301H375.012" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_58" d="M375.486 387.824V443.481" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_59" d="M375.486 315.025V359.162" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_60" d="M467.139 338.502H530.047" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_61" d="M421.519 338.133H375.197" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_62" d="M375.406 315.049V345.505" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_63" d="M462.596 338.493H556.573V341.693" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_64" d="M420.762 338.262H374.918" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_65" d="M375.477 516.83V571.615H556.577V566.23" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_66" d="M516.049 480.16H406.406" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_67" d="M375.479 329.817V359.282" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Path 602" opacity="0.5" d="M378.76 480.322H542.079V569.842H378.76V480.322Z" fill="#2A2A2A" />
                    <path className="no-touch" id="Vector_68" d="M462.236 535.259C465.724 535.259 468.552 532.431 468.552 528.943C468.552 525.455 465.724 522.627 462.236 522.627C458.748 522.627 455.92 525.455 455.92 528.943C455.92 532.431 458.748 535.259 462.236 535.259Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_69" d="M418.901 469.347C420.257 469.347 421.355 468.249 421.355 466.893C421.355 465.538 420.257 464.439 418.901 464.439C417.546 464.439 416.447 465.538 416.447 466.893C416.447 468.249 417.546 469.347 418.901 469.347Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_70" d="M523.01 341.579H474.479V356.484H523.01V341.579Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_71" d="M484.911 414.448H434.562V470.704H484.911V414.448Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_72" d="M486.73 471.257H432.746V473.892H486.73V471.257Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_73" d="M429.083 461.259H408.271V473.891H429.083V461.259Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 613" opacity="0.5" d="M375.436 486.541V516.551" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_74" d="M556.572 474.495V485.826" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_75" d="M422.994 396.155C431.878 396.155 439.08 388.953 439.08 380.069C439.08 371.185 431.878 363.983 422.994 363.983C414.11 363.983 406.908 371.185 406.908 380.069C406.908 388.953 414.11 396.155 422.994 396.155Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_76" d="M501.03 469.347C502.385 469.347 503.484 468.249 503.484 466.893C503.484 465.538 502.385 464.439 501.03 464.439C499.675 464.439 498.576 465.538 498.576 466.893C498.576 468.249 499.675 469.347 501.03 469.347Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_77" d="M482.427 530.717C483.782 530.717 484.881 529.618 484.881 528.263C484.881 526.907 483.782 525.809 482.427 525.809C481.071 525.809 479.973 526.907 479.973 528.263C479.973 529.618 481.071 530.717 482.427 530.717Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_78" d="M486.249 508.995H471.344V547.075H486.249V508.995Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_79" d="M511.203 461.259H490.391V473.891H511.203V461.259Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 387" opacity="0.2" d="M556.572 566.208V341.694" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 393" d="M482.428 523.168V534.014" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_80" d="M553.917 341.194H543.102V568.047H553.917V341.194Z" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_81" d="M548.477 404.86V503.475" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_82" d="M427 406L427 456" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <g onClick={() => clickIn("visit-bedroom/1")} onPointerOver={() => hoverIn("visit-bedroom/1")} onPointerEnter={() => hoverIn("visit-bedroom/1")}><Tooltip trigger={["hover", "click", isHovered("visit-bedroom/1")]} title="VISIT"><path id="visit-8" d="M453 366C456.866 366 460 362.866 460 359C460 355.134 456.866 352 453 352C449.134 352 446 355.134 446 359C446 362.866 449.134 366 453 366Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-bedroom/2")} onPointerOver={() => hoverIn("visit-bedroom/2")} onPointerEnter={() => hoverIn("visit-bedroom/2")}><Tooltip trigger={["hover", "click", isHovered("visit-bedroom/2")]} title="VISIT"><path id="visit-9" d="M394 380C397.866 380 401 376.866 401 373C401 369.134 397.866 366 394 366C390.134 366 387 369.134 387 373C387 376.866 390.134 380 394 380Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-bedroom/3")} onPointerOver={() => hoverIn("visit-bedroom/3")} onPointerEnter={() => hoverIn("visit-bedroom/3")}><Tooltip trigger={["hover", "click", isHovered("visit-bedroom/3")]} title="VISIT"><path id="visit-10" d="M404 426C407.866 426 411 422.866 411 419C411 415.134 407.866 412 404 412C400.134 412 397 415.134 397 419C397 422.866 400.134 426 404 426Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-bedroom/4")} onPointerOver={() => hoverIn("visit-bedroom/4")} onPointerEnter={() => hoverIn("visit-bedroom/4")}><Tooltip trigger={["hover", "click", isHovered("visit-bedroom/4")]} title="VISIT"><path id="visit-11" d="M413 531C416.866 531 420 527.866 420 524C420 520.134 416.866 517 413 517C409.134 517 406 520.134 406 524C406 527.866 409.134 531 413 531Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-bedroom/5")} onPointerOver={() => hoverIn("visit-bedroom/5")} onPointerEnter={() => hoverIn("visit-bedroom/5")}><Tooltip trigger={["hover", "click", isHovered("visit-bedroom/5")]} title="VISIT"><path id="visit-12" d="M445 513C448.866 513 452 509.866 452 506C452 502.134 448.866 499 445 499C441.134 499 438 502.134 438 506C438 509.866 441.134 513 445 513Z" fill="white" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered?.includes('living-room')} title="Living Room">
                <g opacity={opacityClass("living-room")} onClick={() => hoverIn("living-room")} onPointerOver={() => hoverIn("living-room")} onPointerEnter={() => hoverIn("living-room")} id="living-room">

                    <rect onClick={() => hoverIn("living-room")} onPointerOver={() => hoverIn("living-room")} onPointerEnter={() => hoverIn("living-room")} id="living-room" width="255" height="242" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector_83" d="M231.048 243.139H176.473" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_84" d="M191.334 243.136H150.789" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_85" d="M206.117 57.3809H1.5V243.623H64.3711" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_86" d="M231.056 243.124L150.809 243.171" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_87" d="M254.932 145.221V1.5H139.062" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_88" d="M137.52 118.944C140.507 118.893 142.887 116.431 142.836 113.444C142.786 110.457 140.323 108.077 137.336 108.128C134.35 108.179 131.97 110.641 132.02 113.628C132.071 116.615 134.533 118.995 137.52 118.944Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_89" d="M144.874 114.004C149.07 113.932 152.413 110.474 152.341 106.278C152.27 102.083 148.811 98.7402 144.616 98.8115C140.421 98.8828 137.078 102.341 137.149 106.537C137.221 110.732 140.679 114.075 144.874 114.004Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 411" d="M128.061 110.615H48.6758V96.2472C49.7668 92.8208 51.655 89.7021 54.1855 87.1473C56.4647 84.9568 59.4205 83.6058 62.5684 83.3162H111.176C111.176 83.3162 118.242 83.0763 122.912 88.2253C127.582 93.3743 128.06 97.9252 128.06 97.9252L128.061 110.615Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_90" d="M13.5707 108.042H2.63867V193.819H13.5707V108.042Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_91" d="M1.52148 230.339V243.639H64.3445" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_92" d="M204.415 60.2021H143.586V68.9641H204.415V60.2021Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_93" d="M77.6421 163.433C86.3522 163.433 93.4131 156.615 93.4131 148.205C93.4131 139.794 86.3522 132.977 77.6421 132.977C68.932 132.977 61.8711 139.794 61.8711 148.205C61.8711 156.615 68.932 163.433 77.6421 163.433Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_94" d="M104.023 173.21C110.636 173.21 115.997 167.849 115.997 161.236C115.997 154.623 110.636 149.262 104.023 149.262C97.4098 149.262 92.0488 154.623 92.0488 161.236C92.0488 167.849 97.4098 173.21 104.023 173.21Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_95" d="M155.74 194.936C158.16 194.936 160.121 192.974 160.121 190.555C160.121 188.135 158.16 186.174 155.74 186.174C153.321 186.174 151.359 188.135 151.359 190.555C151.359 192.974 153.321 194.936 155.74 194.936Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_96" d="M28.7553 125.434C28.7553 121.816 25.8225 118.883 22.2048 118.883C18.5871 118.883 15.6543 121.816 15.6543 125.434V175.33C15.6543 178.948 18.5871 181.881 22.2048 181.881C25.8225 181.881 28.7553 178.948 28.7553 175.33V125.434Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_97" d="M252.709 89.541H234.184V135.184H252.709V89.541Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 410" d="M138.561 136.287C138.561 132.096 141.073 126.708 141.073 126.708C141.073 126.708 149.217 125.63 152.45 126.708C155.683 127.786 160.112 130.539 159.992 136.287C159.872 142.035 156.878 146.464 152.45 146.823C148.66 147.019 144.863 147.019 141.073 146.823C141.073 146.823 138.561 140.478 138.561 136.287Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 412" d="M138.561 174.842C138.561 170.651 141.073 165.263 141.073 165.263C141.073 165.263 149.217 164.185 152.45 165.263C155.683 166.341 160.112 169.094 159.992 174.842C159.872 180.59 156.878 185.019 152.45 185.378C148.66 185.575 144.863 185.575 141.073 185.378C141.073 185.378 138.561 179.033 138.561 174.842Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_98" d="M83.3514 190.578H61.1484C59.7677 190.578 58.6484 191.697 58.6484 193.078V215.281C58.6484 216.662 59.7677 217.781 61.1484 217.781H83.3514C84.7321 217.781 85.8514 216.662 85.8514 215.281V193.078C85.8514 191.697 84.7321 190.578 83.3514 190.578Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 434" opacity="0.2" d="M149.287 2.46289V56.2456" stroke="white" strokeWidth="2" />
                    <g onClick={() => clickIn("visit-living-room/1")} onPointerOver={() => hoverIn("visit-living-room/1")} onPointerEnter={() => hoverIn("visit-living-room/1")}><Tooltip trigger={["hover", "click", isHovered("visit-living-room/1")]} title="VISIT"><path id="visit-13" d="M140 219C143.866 219 147 215.866 147 212C147 208.134 143.866 205 140 205C136.134 205 133 208.134 133 212C133 215.866 136.134 219 140 219Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/2")} onPointerOver={() => hoverIn("visit-living-room/2")} onPointerEnter={() => hoverIn("visit-living-room/2")}><Tooltip trigger={["hover", "click", isHovered("visit-living-room/2")]} title="VISIT"><path id="visit-14" d="M102 220C105.866 220 109 216.866 109 213C109 209.134 105.866 206 102 206C98.134 206 95 209.134 95 213C95 216.866 98.134 220 102 220Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/3")} onPointerOver={() => hoverIn("visit-living-room/3")} onPointerEnter={() => hoverIn("visit-living-room/3")}><Tooltip trigger={["hover", "click", isHovered("visit-living-room/3")]} title="VISIT"><path id="visit-15" d="M45 169C48.866 169 52 165.866 52 162C52 158.134 48.866 155 45 155C41.134 155 38 158.134 38 162C38 165.866 41.134 169 45 169Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-living-room/4")} onPointerOver={() => hoverIn("visit-living-room/4")} onPointerEnter={() => hoverIn("visit-living-room/4")}><Tooltip trigger={["hover", "click", isHovered("visit-living-room/4")]} title="VISIT"><path id="visit-16" d="M121 128C124.866 128 128 124.866 128 121C128 117.134 124.866 114 121 114C117.134 114 114 117.134 114 121C114 124.866 117.134 128 121 128Z" fill="white" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered?.includes('dining-room')} title="Dining Room">
                <g opacity={opacityClass("dining-room")} onClick={() => hoverIn("dining-room")} onPointerOver={() => hoverIn("dining-room")} onPointerEnter={() => hoverIn("dining-room")} id="dining-room">
                    <rect onClick={() => hoverIn("dining-room")} onPointerOver={() => hoverIn("dining-room")} onPointerEnter={() => hoverIn("dining-room")} id="dining-room" y="242" width="185" height="194" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector_33" d="M195.364 435.648H150.605" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_34" d="M35.1836 284.773V395.505" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_35" d="M35.1815 343.395H1.52148V296.121" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_36" d="M1.52148 342.837V435.648H68.1895" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_37" d="M1.52344 435.829V450.677" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_38" d="M48.1404 303.385C51.4248 303.385 54.0874 300.723 54.0874 297.438C54.0874 294.154 51.4248 291.491 48.1404 291.491C44.8559 291.491 42.1934 294.154 42.1934 297.438C42.1934 300.723 44.8559 303.385 48.1404 303.385Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_39" d="M17.4518 427.332C20.2276 427.332 22.4778 425.082 22.4778 422.306C22.4778 419.53 20.2276 417.28 17.4518 417.28C14.676 417.28 12.4258 419.53 12.4258 422.306C12.4258 425.082 14.676 427.332 17.4518 427.332Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_40" d="M52.2673 311.626H38.0703V376.941H52.2673V311.626Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_41" d="M127.345 310.7H95.1875V381.08H127.345V310.7Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 419" d="M103.702 291.884C104.817 290.788 106.116 289.896 107.539 289.248C108.238 288.942 109.002 288.815 109.762 288.88C110.523 288.944 111.255 289.197 111.893 289.617C113.599 290.868 117.31 294.783 117.31 296.417C117.31 298.051 115.567 300.76 113.848 302.439C112.129 304.118 108.496 306.151 106.479 305.395C104.462 304.639 101.5 300.548 100.745 299.795C99.9897 299.042 99.8137 297.586 100.298 295.998C100.782 294.41 101.891 293.572 103.702 291.884Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 420" d="M78.285 327.017C78.6505 325.496 79.2789 324.052 80.1417 322.748C80.5722 322.118 81.1533 321.605 81.8321 321.257C82.5109 320.908 83.2661 320.735 84.0289 320.752C86.1386 320.909 91.3614 322.261 92.2274 323.647C93.0934 325.033 93.0516 328.254 92.4829 330.59C91.9143 332.925 89.9118 336.574 87.8016 337.002C85.6913 337.429 81.0105 335.53 79.9703 335.292C78.9301 335.055 78.01 333.913 77.5805 332.308C77.151 330.704 77.6447 329.407 78.285 327.017Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 421" d="M78.0785 359.972C77.8749 358.421 77.9439 356.846 78.2822 355.319C78.4585 354.576 78.8174 353.889 79.3265 353.321C79.8356 352.752 80.4786 352.319 81.1973 352.062C83.2239 351.452 88.5866 350.843 89.8922 351.826C91.1977 352.81 92.3137 355.834 92.6198 358.219C92.9259 360.604 92.3638 364.73 90.5461 365.886C88.7285 367.041 83.676 366.946 82.6193 367.097C81.5625 367.248 80.2938 366.511 79.3176 365.167C78.3413 363.822 78.3376 362.434 78.0785 359.972Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 424" d="M106.072 401.142C104.74 400.324 103.56 399.281 102.587 398.058C102.121 397.454 101.813 396.743 101.691 395.99C101.57 395.237 101.638 394.465 101.89 393.745C102.691 391.788 105.59 387.24 107.175 386.844C108.76 386.449 111.81 387.484 113.855 388.746C115.9 390.008 118.751 393.039 118.506 395.177C118.26 397.316 115.009 401.18 114.461 402.095C113.914 403.011 112.544 403.533 110.886 403.446C109.228 403.359 108.148 402.489 106.072 401.142Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 425" d="M146.16 361.964C146.2 363.527 145.967 365.085 145.471 366.568C145.218 367.288 144.79 367.933 144.224 368.445C143.658 368.958 142.974 369.321 142.233 369.501C140.154 369.896 134.759 369.94 133.564 368.826C132.369 367.712 131.576 364.589 131.521 362.186C131.466 359.783 132.456 355.74 134.384 354.781C136.312 353.822 141.325 354.445 142.391 354.406C143.457 354.367 144.641 355.232 145.471 356.67C146.301 358.109 146.16 359.489 146.16 361.964Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 426" d="M143.76 338.442C142.625 339.518 141.311 340.387 139.878 341.011C139.173 341.304 138.407 341.418 137.648 341.34C136.889 341.263 136.161 340.997 135.531 340.567C133.847 339.286 130.204 335.307 130.232 333.673C130.261 332.039 132.051 329.36 133.8 327.712C135.549 326.063 139.216 324.093 141.218 324.885C143.221 325.676 146.112 329.818 146.855 330.584C147.597 331.35 147.747 332.809 147.233 334.388C146.72 335.967 145.599 336.786 143.76 338.442Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_42" d="M182.636 377.778H165.676V418.225H182.636V377.778Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_43" d="M182.636 266.674H165.676V307.121H182.636V266.674Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 433" opacity="0.2" d="M1.52148 245.647V296.116" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Path 603" opacity="0.2" d="M150.655 435.618H68.3164" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_44" d="M185.156 436.265L184.283 353.912" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_45" d="M185.156 323.588L184.283 243.02" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <g onClick={() => clickIn("visit-dining-room/1")} onPointerOver={() => hoverIn("visit-dining-room/1")} onPointerEnter={() => hoverIn("visit-dining-room/1")}><Tooltip trigger={["hover", "click", isHovered("visit-dining-room/1")]} title="VISIT"><path id="visit-2" d="M89 290C92.866 290 96 286.866 96 283C96 279.134 92.866 276 89 276C85.134 276 82 279.134 82 283C82 286.866 85.134 290 89 290Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-dining-room/2")} onPointerOver={() => hoverIn("visit-dining-room/2")} onPointerEnter={() => hoverIn("visit-dining-room/2")}><Tooltip trigger={["hover", "click", isHovered("visit-dining-room/2")]} title="VISIT"><path id="visit-2" d="M150 293C153.866 293 157 289.866 157 286C157 282.134 153.866 279 150 279C146.134 279 143 282.134 143 286C143 289.866 146.134 293 150 293Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-dining-room/3")} onPointerOver={() => hoverIn("visit-dining-room/3")} onPointerEnter={() => hoverIn("visit-dining-room/3")}><Tooltip trigger={["hover", "click", isHovered("visit-dining-room/3")]} title="VISIT"><path id="visit-3" d="M129 272C132.866 272 136 268.866 136 265C136 261.134 132.866 258 129 258C125.134 258 122 261.134 122 265C122 268.866 125.134 272 129 272Z" fill="white" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered?.includes('hall')} title="Entrance Hall">
                <g opacity={opacityClass("hall")} onClick={() => hoverIn("hall")} onPointerOver={() => hoverIn("hall")} onPointerEnter={() => hoverIn("hall")} id="hall">
                    <rect onClick={() => hoverIn("hall")} onPointerOver={() => hoverIn("hall")} onPointerEnter={() => hoverIn("hall")} id="hall" x="199" y="145" width="170" height="97" fill="#C4C4C4" fillOpacity="0.05" />
                    <path className="no-touch" id="Vector_99" d="M316.287 145.197H254.938V135.165" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_100" d="M368.535 144.973H373.091" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Path 435" d="M368.464 240.888V146.205H359.764" stroke="#C4C4C4" />
                    <path className="no-touch" id="Vector_101" d="M295.063 179.071C299.616 179.071 303.306 175.38 303.306 170.828C303.306 166.275 299.616 162.585 295.063 162.585C290.511 162.585 286.82 166.275 286.82 170.828C286.82 175.38 290.511 179.071 295.063 179.071Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_102" d="M274.055 159.989C276.629 159.989 278.717 157.919 278.717 155.364C278.717 152.81 276.629 150.739 274.055 150.739C271.48 150.739 269.393 152.81 269.393 155.364C269.393 157.919 271.48 159.989 274.055 159.989Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_103" d="M310.57 148.327H260.922V160.592H310.57V148.327Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_104" d="M300.244 146.973H271.699V147.179H300.244V146.973Z" stroke="white" strokeWidth="2" />
                    <path className="no-touch" id="Vector_105" d="M368.228 146.444H199.037V241.212H368.228V146.444Z" stroke="white" strokeWidth="2" strokeOpacity="0.25" />
                    <path className="no-touch" id="Vector_106" d="M375.027 251.379V242.599L328.807 242.629" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_107" d="M302.314 243.157H257.645" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_108" d="M231.044 243.14H191.287" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <path className="no-touch" id="Vector_109" d="M273.893 243.14H257.678" fill="none" stroke="#7A303F" strokeWidth="3" />
                    <g onClick={() => clickIn("visit-hall/1")} onPointerOver={() => hoverIn("visit-hall/1")} onPointerEnter={() => hoverIn("visit-hall/1")}><Tooltip trigger={["hover", "click", isHovered("visit-hall/1")]} title="VISIT"><path id="visit-17" d="M352 222C355.866 222 359 218.866 359 215C359 211.134 355.866 208 352 208C348.134 208 345 211.134 345 215C345 218.866 348.134 222 352 222Z" fill="white" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-hall/2")} onPointerOver={() => hoverIn("visit-hall/2")} onPointerEnter={() => hoverIn("visit-hall/2")}><Tooltip trigger={["hover", "click", isHovered("visit-hall/2")]} title="VISIT"><path id="visit-18" d="M306 217C309.866 217 313 213.866 313 210C313 206.134 309.866 203 306 203C302.134 203 299 206.134 299 210C299 213.866 302.134 217 306 217Z" fill="white" /></Tooltip></g>
                </g>
            </Tooltip>

        </svg>
    )
})

export { ActMapFull }