import React from 'react'
import PropTypes from 'prop-types'

const OculusIcon = React.memo(({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
      <path id="Path_236" data-name="Path 236" d="M11.067,368.5a1.01,1.01,0,0,0-1.067.941v6.118a1.01,1.01,0,0,0,1.067.941h4.992a1.043,1.043,0,0,0,1.046-.757l.328-1.444a.494.494,0,0,1,.158-.261.634.634,0,0,1,.819,0,.494.494,0,0,1,.158.261l.328,1.444a1.043,1.043,0,0,0,1.046.757h4.992A1.01,1.01,0,0,0,26,375.559v-6.118a1.01,1.01,0,0,0-1.067-.941Zm2.648,5.143a1.428,1.428,0,1,0-1.429-1.429A1.429,1.429,0,0,0,13.714,373.643Zm10-1.428a1.429,1.429,0,1,1-1.429-1.429A1.428,1.428,0,0,1,23.714,372.215Z" transform="translate(-10 -368.5)" fill={fill}/>
    </svg>
  )
})

OculusIcon.propTypes = {
  fill: PropTypes.string
}

OculusIcon.defaultProps = {
  fill: "#FFF"
}

export {OculusIcon}